import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((facultyPortalTheme) => ({
  // Universal for Desktop and Mobile View
  linkContainer: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "&:focus": {
      color: "#8a1500",
      outline: "auto",
      outlineColor: "#000000",
    },
    "&:hover": {
      color: "#8a1500",
      cursor: "pointer",
    },
  },
  linkText: {
    "&:hover": {
      color: "#8a1500",
      marginRight: "10px",
      transition: "all .2s",
    },
  },
  arrowImage: {
    marginLeft: "8px",
    width: "27px",
    height: "22px",
    "&:hover": {
      marginLeft: "18px",
      transition: "all .2s",
    },
  },
  linkTextWhite: {
    color: "#FFFFFF",
    "&:hover": {
      marginRight: "10px",
      transition: "all .2s",
    },
  },
  whiteArrowImage: {
    marginLeft: "8px",
    filter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(110%) contrast(101%)",
    width: "27px",
    height: "22px",
    "&:hover": {
      marginLeft: "18px",
      transition: "all .2s",
    },
  },
  hideDiv: {
    display: "none",
  },
  surveyText : {
    color :"#30718d",
    marginLeft: "8px",
    "&:hover": {
      marginLeft: "10px",
      transition: "all .2s",
      cursor: "pointer"
    },
  }
}));

export default useStyles;
