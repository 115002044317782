import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  buttonContainer: {
    display:"flex",
    alignItems:"flex-end",
    flexDirection : "column",
  },
  hide: {
    display : "none"
  },
  desktopLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "5%",
  },
  mobileLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "12px",
  },
  buttonContainerMobile: {
    display:"flex",
    alignItems:"center",
    flexDirection : "column",
    padding: "0px 10px 0px 10px"
  },
  accomPageTablet: {
    display:"flex",
    alignItems:"flex-end",
    flexDirection : "column",
  },

}));

export default useStyles;