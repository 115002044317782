import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rowContainer: {
    marginBottom: "60px",
    fontWeight: "400",
  },
  
  portalTitle: {
    color: "#DC3727",
    margin: "80px 0 15px 0",
    letterSpacing: "0.2em"
  },
  sun: {
    transform: "scale(.4)",
  },
  sunDiv: {
    display: "flex",
    height: "100px",
    width: "100px",
    alignItems: "center",
    justifyContent: "center",
  },

  // Mobile View
  welcomeContainerMobile: {
    // display: "flex",
    // alignItems: "center",
    // marginTop: "40px",
    // width: "327px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  // textContainer: {
  //   display: "flex",
  //   textAlign: "center",
  // },
  sunMobile: {
    transform: "scale(.25)",
  },
  sunDivMobile: {
    display: "flex",
    height: "45px",
    width: "45px",
    alignItems: "center",
    justifyContent: "center",
  },
});
