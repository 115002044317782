import { GET_DEMOGRAPHICS, GET_FACULTY_CONTACTS, GET_PERSON_WITH_NAME_SUCCESS, GET_PERSON_WITH_NAME_FAILURE } from "./actionTypes";
import { getFacultyDemographics, getPersonWithName } from "../../services/persons/v1/persons";
import { getFacultyContacts } from "../../services/contacts/v1/contacts";
import { selectPersonByIrnAndType } from "./personsWithNameSelector";

export const getDemographics = (personId) => async (dispatch) => {
  await getFacultyDemographics(personId)
    .then((response) => {
      dispatch({ type: GET_DEMOGRAPHICS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_DEMOGRAPHICS, payload: error.request });
    });
};

export const getFacultyContactsInfo = (personId) => async (dispatch) => {
  await getFacultyContacts(personId)
    .then((response) => {
      dispatch({ type: GET_FACULTY_CONTACTS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_FACULTY_CONTACTS, payload: error.request });
    });
};

export const getPersonInfoWithName = (irn, personType) => async (dispatch, getState) => {

  const state = getState();

  if (selectPersonByIrnAndType(
    state,
    irn, 
    personType)) {
    return;
  }

  // If person doesn't exist in redux already, proceed with API call
  await getPersonWithName(irn, personType)
    .then((response) => {
      dispatch({ type: GET_PERSON_WITH_NAME_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_PERSON_WITH_NAME_FAILURE, payload: error.request });
    });
};