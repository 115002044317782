import { Route, Switch } from "react-router";
import { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import MyCoursesPage from "./modules/myCourses/components/MyCoursesPage";
import CoursePreferences from "./modules/coursePreferences/components/CoursePreferences";
import EarlyAlertSubmission from "./modules/earlyAlerts/components/EarlyAlertSubmission";
import EarlyAlertSuccessMessage from "./modules/earlyAlerts/components/EarlyAlertSuccessMessage";
import InterstitialForAlerts from "./modules/earlyAlerts/components/InterstitialForAlerts";
import AuthedRoute from "./components/AuthedRoute";
import CallbackComponentWrapper from "./components/CallbackComponentWrapper";
import Dashboard from "./modules/dashboard/components/Dashboard";
import LogoutContainer from "./components/LogoutContainer";
import { getDemographics, getFacultyContactsInfo } from "./redux/demographics/demographicsActions";
import HelpPage from "./modules/helpPage/components/HelpPage";
import ExpenseSubmissions from "./modules/expenseSubmissions/components/ExpenseSubmissions";
import Solicitations from "./modules/solicitations/components/Solicitations";
import { history } from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "./components/SnackbarContentWrapper";
import Chatbot from "./components/chatbot/Chatbot";
import axios from "axios";
// import MaintenancePage from "../src/modules/maintenance/MaintenancePage";
import DummyHeader from "./components/header/DummyHeader";
import FeedbackContainer from "./components/feedbackSurvey/FeedbackContainer";
import FacultyWorkshops from "./modules/facultyWorkshops/components/FacultyWorkshops"; 
import HelpDeskSubmission from "./modules/helpDeskSubmission/components/HelpDeskSubmission";
import HelpDeskSuccess from "./modules/helpDeskSubmission/components/HelpDeskSuccess";
import ManageAccommodations from "./modules/studentAccommodations/components/ManageAccommodations";

function App(props) {
  const { user, isLoadingUser } = useSelector((state) => state.oidc);
  const [openSuccess, setOpenSuccess] = useState(false);

  // useState for chatbot service
  const [profileId, setProfileId] = useState("");
  const [ip, setIP] = useState("");
  const [email, setEmail] = useState("");

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    if (user) {
      props.getDemographics(user.profile[`custom:FACULTY_PERSON_ID`]);
      props.getFacultyContactsInfo(user.profile[`custom:FACULTY_PERSON_ID`]);
      // props.getFacultyContactsInfo("FAKEPERSONID"); // fake PERSON ID for testing purposes
      // props.getDemographics("FAKEIRN"); // fake IRN for testing purposes
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  window.demographics = props.demographics;

  useEffect(() => {
    if (
      props.demographics.status !== undefined &&
      props.demographics.status !== 200
    ) {
      setOpenSuccess(true);
    }
  }, [props.demographics.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.demographics.externalSystemIds) {
      if (
        props.demographics[`externalSystemIds`].PROFILE_ID &&
        props.demographics[`externalSystemIds`].PROFILE_ID.length
      ) {
        setProfileId(props.demographics[`externalSystemIds`].PROFILE_ID[0]);
      }
      if (
        props.demographics[`externalSystemIds`].UNIVERSITY_EMAIL &&
        props.demographics[`externalSystemIds`].UNIVERSITY_EMAIL.length
      ) {
        setEmail(props.demographics[`externalSystemIds`].UNIVERSITY_EMAIL[0]);
      }
    }
  }, [props.demographics.externalSystemIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getData();
  }, []);

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const chatbotStartChatRef = useRef(null);

  return (
    <>
      {/* <MaintenancePage /> */}
      <ConnectedRouter history={history}>
        {!user && !isLoadingUser ? null : <Header />}
        {!user && !isLoadingUser ? null : <DummyHeader />}
        {props.demographics.firstName && props.demographics.lastName ? (
          <Chatbot
            chatbotStartChatRef={chatbotStartChatRef}
            firstName={`${props.demographics.firstName}`}
            lastName={`${props.demographics.lastName}`}
            profileId={`${profileId}`}
            facultyIRN={`${props.demographics.issuerId}`}
            phoneNumber={""}
            ipAddress={ip}
            email={`${email}`}
          />
        ) : (
          ""
        )}
        {!user && !isLoadingUser ? null : <FeedbackContainer />}
        <Switch>
          <Route path="/oidc/callback" component={CallbackComponentWrapper} />
          <Route path="/logout" component={LogoutContainer} />
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/solicitations"
              render={() => <Solicitations />}
            />
          ) : (
            <Route path="/solicitations" component={Solicitations} />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/expensesubmissions"
              render={() => <ExpenseSubmissions />}
            />
          ) : (
            <Route path="/expensesubmissions" component={ExpenseSubmissions} />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute exact path="/help" render={() => <HelpPage />} />
          ) : (
            <Route path="/help" component={HelpPage} />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/mycourses"
              render={() => <MyCoursesPage />}
            />
          ) : (
            <Route path="/mycourses" component={MyCoursesPage} />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/coursepreferences"
              render={() => <CoursePreferences />}
            />
          ) : (
            <Route path="/coursepreferences" component={CoursePreferences} />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/earlyalertsubmission"
              search="?studentProfileId=:profile_id&courseOfferingId=:course_offering_id"
              render={() => <EarlyAlertSubmission />}
            />
          ) : (
            <Route
              path="/earlyalertsubmission"
              search="?studentProfileId=:profile_id&courseOfferingId=:course_offering_id"
              component={EarlyAlertSubmission}
            />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/interstitialforalerts"
              search="?studentProfileId=:profile_id&courseOfferingId=:course_offering_id"
              render={() => <InterstitialForAlerts />}
            />
          ) : (
            <Route
              path="/interstitialforalerts"
              search="?studentProfileId=:profile_id&courseOfferingId=:course_offering_id"
              component={InterstitialForAlerts}
            />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/earlyalertsuccessmessage"
              search="?studentProfileId=:profile_id"
              render={() => <EarlyAlertSuccessMessage />}
            />
          ) : (
            <Route
              path="/earlyalertsuccessmessage"
              search="?studentProfileId=:profile_id"
              component={EarlyAlertSuccessMessage}
            />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/manageAccommodations"
              render={() => <ManageAccommodations />}
            />
          ) : (
            <Route
              path="/manageAccommodations"
              component={ManageAccommodations}
            />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/helpdesksubmission"
              render={() => <HelpDeskSubmission />}
            />
          ) : (
            <Route
              path="/helpdesksubmission"
              component={HelpDeskSubmission}
            />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/helpdesksuccess"
              render={() => <HelpDeskSuccess />}
            />
          ) : (
            <Route
              path="/helpdesksuccess"
              component={HelpDeskSuccess}
            />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute
              exact
              path="/facultyworkshops"
              render={() => <FacultyWorkshops />}
            />
          ) : (
            <Route path="/facultyworkshops" component={FacultyWorkshops} />
          )}
          {!user && !isLoadingUser ? (
            <AuthedRoute exact path="/" render={() => <Dashboard />} />
          ) : (
            <Route path="/" component={Dashboard} />
          )}
        </Switch>
        {!user && !isLoadingUser ? null : <Footer />}
      </ConnectedRouter>
      {openSuccess
        ? renderSnackbar("error", "Oops! Something went wrong.")
        : ""}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    facultyContacts: state.facultyContacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDemographics: (personId) => dispatch(getDemographics(personId)),
    getFacultyContactsInfo: (personId) => dispatch(getFacultyContactsInfo(personId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
