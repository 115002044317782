import {GET_STUDENT_ACCOMMODATIONS} from "./actionTypes";

// eslint-disable-next-line 
export default (state = [], action) => {
    switch(action.type) {
        case GET_STUDENT_ACCOMMODATIONS :
            // if (state.length === 0) {
            //     return action.payload;
            // } else {
            //     return [...state, action.payload];
            // };
            return [...state, action.payload]
        default:
            return state;  
    };
};