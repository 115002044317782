import { makeStyles } from "@material-ui/core/styles";
import BackgroundPhoenix from "./images/background.png";

export default makeStyles({
  // Universal Text Style
  subtitle1: {
    // card titles
    color: "#3A4F59",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  subtitle2: {
    // card titles, mobile current course headings
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "21px",
    lineHeight: "24px",
  },
  subtitle3: {
    // footer, header, quicklinks cards,
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "18px",
  },
  subtitle4: {
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "18px",
  },
  body1: {
    // common text
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
  },
  body2: {
    // common text in solicitations and current courses
    color: "#001823",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21px",
  },
  body3: {
    // course history discription
    color: "#5E7078",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "24px",
    marginLeft: "5px",
  },
  body4: {
    // supervisor and scheduler contact info
    color: "#3A4F59",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "18px",
  },
  body5: {
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
  },

  // Lists
  unorderedList: {
    listStyle: "none",
    padding: "0px",
    margin: "0px",
  },

  // ***** DESKTOP VIEW *****
  // Text
  courseHeading1: {
    // course history and course preference headers
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
  },
  courseHeading2: {
    // current course and solications headers
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
  },
  linkHeading1: {
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#001823",
  },

  // Page Container
  pageContainer: {
    height: "100%",
    width: "100%",
    // minWidth: "1250px",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "25% 0%",
    overflowX: "hidden",
    overflowY: "hidden", //
  },
  tabletPageContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "25% 0%",
    overflowX: "hidden",
  },

  // Header Container
  headerContainer: {
    width: "100%",
    height: "78px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    minWidth: "1250px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  },

  // Footer Container
  footerContainer: {
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: "1250px",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "75px 0px 75px 0px",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  // Notifications Container
  notificationContainer: {
    width: "100%",
    display: "flex",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "3px",
    background: "#FFFFFF",
    borderLeft: "5px solid #DC3727",
    marginTop: "15px",
    padding: "10px 0px",
  },
  tabletNotificationContainer: {
    //minWidth: "600px",
    display: "flex",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "3px",
    background: "#FFFFFF",
    borderLeft: "5px solid #DC3727",
    marginTop: "15px",
    padding: "10px 0px",
  },

  // ***** MOBILE VIEW *****
  // Text
  footerHeaderMobile: {
    color: "#000304",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "17px",
    lineHeight: "20px",
  },

  // Page Container
  pageContainerMobile: {
    // height: "100%",
    // width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: "#F2F3F3",
    paddingTop: "24px",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "317px 396px",
    backgroundPosition: "center 48px",
  },

  // Header Container
  headerContainerMobile: {
    height: "94px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  },
  profileContainerMobile: {
    width: "100%",
    background: "#E2E6E7",
  },
  navigateContainerMobile: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    background: "#FFFFFF",
  },

  // Footer Container
  footerContainerMobile: {
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    padding: "20px 25px",
  },

  // Notification Container
  notificationContainerMobile: {
    minWidth: "320px",
    display: "flex",
    borderLeft: "5px solid #DC3727",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "3px",
    background: "#FFFFFF",
    padding: "10px 0px",
    marginTop: "15px",
  },
});
