import { React, useEffect, useState } from "react";
import styles from "../intestitialForAlertsStyles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { 
    useMediaQuery, 
    Box,
    Grid,
    Link,
    Typography,
    Snackbar,
} from "@material-ui/core";
import BlackArrow from "../../../images/BlackArrow.svg";
import CardLink from "../../../components/CardLink";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { connect } from "react-redux";
import { getStudentProfile, getCourseOfferingInfo } from "../../../redux/earlyAlerts/earlyAlertsActions";
import {useLocation} from "react-router-dom";


const InterstitialForAlertsPage = (props) => {

    const location = useLocation();
    const profile_id = new URLSearchParams(location.search).get('studentProfileId');
    const course_offering_id = new URLSearchParams(location.search).get('courseOfferingId');

    document.title = "Faculty Portal Interstitial Page";

    const classes = styles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));


    // manage state change for showing successful submission page or submission error message
    const [errorMessage, setErrorMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);


    // useEffect to call student profile api to populate redux store
    useEffect(() => {
        props.getStudentProfile(profile_id);
        props.getCourseOfferingInfo(course_offering_id)
    }, [profile_id, course_offering_id]); // eslint-disable-line react-hooks/exhaustive-deps

    
    // state to determine if profileId and courseOfferingId is properly passed in
    const [validateProfileId, setValidateProfileId] = useState()
    const [validateCourseOfferingId, setValidateCourseOfferingId] = useState()

    useEffect(() => {
        if(profile_id) {
            setValidateProfileId(true)
        } else {
            setValidateProfileId(false)
        }
        if(course_offering_id) {
            setValidateCourseOfferingId(true)
        } else {
            setValidateCourseOfferingId(false)
        }
    }, [profile_id, course_offering_id]) // eslint-disable-line react-hooks/exhaustive-deps


    // code to handle error messages occuring on submit.
    const renderSnackbar = (snackbarType, message) => (
        <>
        <Snackbar
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
            }}
            open={openSuccess}
            autoHideDuration={null}
            onClose={handleClose}
        >
            <SnackbarContentWrapper
            onClose={handleClose}
            variant={snackbarType}
            message={message}
            noRetryButton="true"
            />
        </Snackbar>
        </>
    );

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        setOpenSuccess(false);
    };

    useEffect(() => {
        if (errorMessage !== "") {
        setOpenSuccess(true);
        }
    }, [errorMessage]);


    // handle snack bar error message on initial render of page.
    // checks to see that faculty, student, and course info is present in student and faculty fields.
    useEffect(() => {
        if(Object.keys(props.facultyDemographics).length !== 0) {
            if(props.facultyDemographics.status === undefined) {
                if (
                    (
                        props.courseOffering.status !== undefined &&
                        props.courseOffering.status !== 200
                    )
                    ||
                    (
                        props.studentProfile.status !== undefined &&
                        props.studentProfile.status !== 200
                    )
                ){
                    setErrorMessage(
                        <Typography>
                            {<b>{`Something went wrong`}</b>}{<br/>}
                            {`We couldn’t retrieve the student or course details.  `}
                            {`Please try again – if this issue continues, `} 
                            {`please reach out to Tech Support at (800) 800-3493.`}
                        </Typography>
                    )
                }  
            }
        }
    }, [props.studentProfile.status, props.courseOffering.status, props.facultyDemographics.status, props.facultyDemographics]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(Object.keys(props.facultyDemographics).length !== 0) {
            if(props.facultyDemographics.status === undefined) {
                if(props.courseOffering.courseCode === null) {
                    setErrorMessage(
                        <Typography>
                            {<b>{`Something went wrong`}</b>}{<br/>}
                            {`We couldn’t retrieve the student or course details.  `}
                            {`Please try again – if this issue continues, `} 
                            {`please reach out to Tech Support at (800) 800-3493.`}
                        </Typography>
                    )
                }
            }
        }
    }, [props.courseOffering.courseCode, props.facultyDemographics.status, props.facultyDemographics]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(Object.keys(props.facultyDemographics).length !== 0) {
            if(props.facultyDemographics.status === undefined) {
                if(validateCourseOfferingId === false || validateProfileId === false) {
                    setErrorMessage(
                        <Typography>
                            {<b>{`Something went wrong`}</b>}{<br/>}
                            {`We couldn’t retrieve the student or course details.  `}
                            {`Please try again – if this issue continues, `} 
                            {`please reach out to Tech Support at (800) 800-3493.`}
                        </Typography>
                    )
                }
            }
        }
    }, [validateCourseOfferingId, validateProfileId, props.facultyDemographics.status, props.facultyDemographics]) // eslint-disable-line react-hooks/exhaustive-deps



    return (
      <ThemeProvider theme={facultyPortalTheme}>
        <Box
          id="pageContainer"
          role="main"
          className={
            matches
              ? classes.pageContainer
              : tabletMatches
              ? classes.pageContainerTablet
              : classes.pageContainerMobile
          }
        >
          <Grid
            container
            md={12}
            sm={11}
            xs={10}
            className={
              matches
                ? classes.mainPageContent
                : tabletMatches
                ? classes.mainPageContentTablet
                : classes.mainPageContentMobile
            }
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              className={
                matches
                  ? classes.linkContainer
                  : tabletMatches
                  ? classes.linkContainerTablet
                  : classes.linkContainerMobile
              }
            >
              <Link
                id="backToDashboardLink"
                data-testid="back_to_dashboard_link"
                underline="none"
                className={classes.backToDashboardLink}
                href="/"
              >
                <img className={classes.arrowImage} src={BlackArrow} alt="" />
                <Typography>{`Course Dashboard`}</Typography>
              </Link>
            </Grid>

            <Grid
                item
                className={matches 
                    ? classes.pageHeaderContainer 
                    : classes.pageHeaderContainerMobile}
            >
                <Box>
                    <Typography
                        id="pageHeader"
                        data-testid="page_header"
                        component="h1"
                        className={matches 
                            ? classes.pageHeader 
                            : tabletMatches 
                            ? classes.pageHeaderTablet 
                            : classes.pageHeaderMobile 
                        }
                    >
                        {"Submit an Alert"}
                    </Typography>
                </Box>
            </Grid>
                <Grid
                    item
                    className={matches 
                        ? classes.pageInfoContainer 
                        : classes.pageInfoContainerMobile
                    }
                >
                    <Box
                        sx={{marginBottom: "16px"}}
                    >
                        <Typography
                            id='pageIntro'
                            data-testid="page_intro"
                            className={classes.infoText}
                        >
                            {`You can submit the following alert(s) on student:`}
                        </Typography>
                    </Box>
                    <Box sx={{marginBottom: "16px"}}>
                        <Typography
                            id="studentName"
                            data-testid="student_name"
                            component="h2"
                            className={classes.subHeader}
                        >
                            {
                                (props.studentProfile[0] !== undefined && props.studentProfile[0] !== null && props.studentProfile !== {}) &&
                                `${props.studentProfile[0].firstName.toUpperCase()} ${props.studentProfile[0].lastName.toUpperCase()}`
                            }
                        </Typography>
                    </Box>
                    <Box sx={{marginBottom: "16px"}} data-testid="avt_link">
                        <CardLink
                            content={"Academic Violations Tracking Alert"}
                            link={
                                (props.studentProfile[0] !== undefined && props.studentProfile[0] !== null && props.studentProfile !== {}) ?
                                `${process.env.REACT_APP_AVT_URL}?irn=${props.facultyDemographics.issuerId}&CourseNum=${course_offering_id}&stuIRN=${props.studentProfile[0].issuerId}`
                                :
                                `${process.env.REACT_APP_AVT_URL}?irn=${props.facultyDemographics.issuerId}&CourseNum=${course_offering_id}&stuIRN=${null}`
                            }
                            externalLink={true}
                            ariaId="academic_violations_tracking_alert"
                            ariaRead="Go to Academic Violations Tracking Alert Page"
                        />
                    </Box>
                    <Box
                        sx={matches
                            ? {marginBottom: "184px"}
                            : tabletMatches
                            ? {marginBottom: "112px"}
                            : {marginBottom: "96px"}
                        }
                        data-testid="early_feedback_alert_link"
                    >
                        <CardLink
                            content={"Early Feedback Alert"}
                            link={`/earlyalertsubmission?studentProfileId=${profile_id}&courseOfferingId=${course_offering_id}`}
                            ariaId="early_feedback_alert"
                            ariaRead="Go to Early Feedback Alert Page"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
            {openSuccess &&
                (errorMessage !== ""
                ? renderSnackbar("error", errorMessage)
                : "")}
        </ThemeProvider>
    );
}; 

const mapStateToProps = (state) => {
    return {
        facultyDemographics: state.demographics,
        studentProfile: state.studentProfile,
        courseOffering: state.courseOffering,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getStudentProfile: (profileId) => dispatch(getStudentProfile(profileId)),
        getCourseOfferingInfo: (courseOfferingId) => dispatch(getCourseOfferingInfo(courseOfferingId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterstitialForAlertsPage);