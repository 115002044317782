import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  mainContent: {
    // width: "812px",
    // margin: "0 auto"
    justifyContent: "center",
    margin: "0 8% 0 8%"
  },
  title: {
    marginTop: "57px",
    marginBottom: "15px",
  },

  desktopWelcome: {
    marginBottom: "17px",
  },

  // Mobile View
  titleMobile: {
    width: "327px",
    display: "flex",
    justifyContent: "flex-start",
    margin: "50px 0px 10px 0px",
  },

  mainContentMobile: {
    minWidth: "330px",
    margin: "0 auto",
    justifyContent: "center",
  },

  mobileWelcome: {
    margin: "0 auto",
  },
});
