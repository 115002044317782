import { React, useState, useEffect } from "react";
import styles from "../earlyAlertSubmissionStyles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../facultyPortalTheme";
import {
    useMediaQuery,
    Box,
    Grid,
    Link,
    Typography,
    Button,
    TextField,
    Snackbar,
} from "@material-ui/core";
import ErrorIcon from "@mui/icons-material/Error";
import LockIcon from "@mui/icons-material/Lock";
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import InputAdornment from "@mui/material/InputAdornment";
import BlackArrow from "../../../images/BlackArrow.svg";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import {useLocation} from "react-router-dom";
import { getCourseOfferingInfo, getStudentProfile } from "../../../redux/earlyAlerts/earlyAlertsActions";
import { connect } from "react-redux";

const EarlyAlertSubmissionPage = (props) => {

document.title = "Faculty Portal Early Alert Submission Page";

  const classes = styles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));

  const location = useLocation();
  const profile_id = new URLSearchParams(location.search).get('studentProfileId');
  const course_offering_id = new URLSearchParams(location.search).get('courseOfferingId');
  const salesforceWebFormRecordType = "0120c000001FxkI"

  // manage state change for submission error message
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  // manage state changes for comments text area
  const [comments, setComments] = useState("");

  // manage state changes for input fields
  const [studentIrnInput, setStudentIrnInput] = useState("");
  const [studentFullNameInput, setStudentFullNameInput] = useState("");
  const [facultyNameInput, setFacultyNameInput] = useState("");
  const [facultyEmailInput, setFacultyEmailInput] = useState("");
  const [courseIdInput, setCourseIdInput] = useState("");

  const studentAndFacultyFields = [
    {
      id: "studentIRN",
      datatestID: "student_IRN",
      ariaLabel: "student-irn",
      value: "studentIRN",
      label: "Student's IRN",
      inputVal: studentIrnInput,
    },
    {
      id: "studentFullName",
      datatestID: "student_full_name",
      ariaLabel: "student-full-name",
      value: "studentFullName",
      label: "Student's Full Name",
      inputVal: studentFullNameInput,
    },
    {
      id: "facultyName",
      datatestID: "faculty_name",
      ariaLabel: "faculty-name",
      value: "facultyName",
      label: "Faculty Name",
      inputVal: facultyNameInput,
    },
    {
      id: "facultyEmail",
      datatestID: "faculty_email",
      ariaLabel: "faculty-email",
      value: "facultyEmail",
      label: "Faculty Email",
      inputVal: facultyEmailInput,
    },
    {
      id: "courseID",
      datatestID: "course_ID",
      ariaLabel: "course-id",
      value: "courseID",
      label: "Course ID",
      inputVal: courseIdInput,
    },
  ];

  // manage state changes for check all that apply checkboxes
  const [checkAllThatApplyOptions, setCheckAllThatApplyOptions] = useState([]);
  const [concernCheckboxOne, setConcernCheckboxOne] =
    useState(false);
  const [concernCheckboxTwo, setConcernCheckboxTwo] =
    useState(false);
  const [concernCheckboxThree, setConcernCheckboxThree] =
    useState(false);
  const [concernCheckboxFour, setConcernCheckboxFour] = useState(false);
  const [concernCheckboxFive, setConcernCheckboxFive] = useState(false);

  const handleCheckAllThatApplyChange = (value) => {
    switch (value) {
      case "professionalism":
        setConcernCheckboxOne(!concernCheckboxOne);
        break;
      case "qualityOfSubmittedWork":
        setConcernCheckboxTwo(!concernCheckboxTwo);
        break;
      case "notReadingFeedback":
        setConcernCheckboxThree(!concernCheckboxThree);
        break;
      case "technologyIssues":
        setConcernCheckboxFour(!concernCheckboxFour);
        break;
      case "otherConcerns":
        setConcernCheckboxFive(!concernCheckboxFive);
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    setCheckAllThatApplyOptions([
      {
        id: "concerns-checkbox-1",
        datatestID: "professionalism",
        ariaLabel: "professionalism",
        value: "professionalism",
        label: "Professionalism",
        checked: concernCheckboxOne,
      },
      {
        id: "concerns-checkbox-2",
        datatestID: "quality-of-submitted-work",
        ariaLabel: "quality of submitted work",
        value: "qualityOfSubmittedWork",
        label: "Quality of Submitted Work",
        checked: concernCheckboxTwo,
      },
      {
        id: "concerns-checkbox-3",
        datatestID: "not-reading-feedback/following-directions",
        ariaLabel: "not reading feedback/following directions",
        value: "notReadingFeedback",
        label: "Not Reading Feedback/Following Directions",
        checked: concernCheckboxThree,
      },
      {
        id: "concerns-checkbox-4",
        datatestID: "technology-issues",
        ariaLabel: "technology issues",
        value: "technologyIssues",
        label: "Technology Issues",
        checked: concernCheckboxFour,
      },
      {
        id: "concerns-checkbox-5",
        datatestID: "other-concerns",
        ariaLabel: "other concerns",
        value: "otherConcerns",
        label: "Other",
        checked: concernCheckboxFive,
      },
    ]);
  }, [
    concernCheckboxOne,
    concernCheckboxTwo,
    concernCheckboxThree,
    concernCheckboxFour,
    concernCheckboxFive,
  ]);

  // manage state changes for week options checkboxes
  const [weekOptions, setWeekOptions] = useState([]);
  const [weekOneOption, setWeekOneOption] = useState(false);
  const [weekTwoOption, setWeekTwoOption] = useState(false);
  const [weekThreeOption, setWeekThreeOption] = useState(false);
  const [weekFourOption, setWeekFourOption] = useState(false);
  const [weekFiveOption, setWeekFiveOption] = useState(false);
  const [weekSixOption, setWeekSixOption] = useState(false);
  const [weekSevenOption, setWeekSevenOption] = useState(false);
  const [weekEightOption, setWeekEightOption] = useState(false);
  const [weekNineOption, setWeekNineOption] = useState(false);
  const [weekTenOption, setWeekTenOption] = useState(false);
  const [weekElevenOption, setWeekElevenOption] = useState(false);
  const [weekTwelveOption, setWeekTwelveOption] = useState(false);
  const [weekThirteenOption, setWeekThirteenOption] = useState(false);
  const [weekFourteenOption, setWeekFourteenOption] = useState(false);
  const [weekFifteenOption, setWeekFifteenOption] = useState(false);
  const [weekSixteenOption, setWeekSixteenOption] = useState(false);

  const handleWeekOptionsChange = (weekValue) => {
    switch (weekValue) {
      case "weekOne":
        setWeekOneOption(!weekOneOption);
        break;
      case "weekTwo":
        setWeekTwoOption(!weekTwoOption);
        break;
      case "weekThree":
        setWeekThreeOption(!weekThreeOption);
        break;
      case "weekFour":
        setWeekFourOption(!weekFourOption);
        break;
      case "weekFive":
        setWeekFiveOption(!weekFiveOption);
        break;
      case "weekSix":
        setWeekSixOption(!weekSixOption);
        break;
      case "weekSeven":
        setWeekSevenOption(!weekSevenOption);
        break;
      case "weekEight":
        setWeekEightOption(!weekEightOption);
        break;
      case "weekNine":
        setWeekNineOption(!weekNineOption);
        break;
      case "weekTen":
        setWeekTenOption(!weekTenOption);
        break;
      case "weekEleven":
        setWeekElevenOption(!weekElevenOption);
        break;
      case "weekTwelve":
        setWeekTwelveOption(!weekTwelveOption);
        break;
      case "weekThirteen":
        setWeekThirteenOption(!weekThirteenOption);
        break;
      case "weekFourteen":
        setWeekFourteenOption(!weekFourteenOption);
        break;
      case "weekFifteen":
        setWeekFifteenOption(!weekFifteenOption);
        break;
      case "weekSixteen":
        setWeekSixteenOption(!weekSixteenOption);
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    setWeekOptions([
      {
        id: "week-one-checkbox",
        datatestID: "week_one",
        ariaLabel: "week-one",
        value: "weekOne",
        label: "Week 1",
        checked: weekOneOption,
      },
      {
        id: "week-two-checkbox",
        datatestID: "week_two",
        ariaLabel: "week-two",
        value: "weekTwo",
        label: "Week 2",
        checked: weekTwoOption,
      },
      {
        id: "week-three-checkbox",
        datatestID: "week_three",
        ariaLabel: "week-three",
        value: "weekThree",
        label: "Week 3",
        checked: weekThreeOption,
      },
      {
        id: "week-four-checkbox",
        datatestID: "week_four",
        ariaLabel: "week-four",
        value: "weekFour",
        label: "Week 4",
        checked: weekFourOption,
      },
      {
        id: "week-five-checkbox",
        datatestID: "week_five",
        ariaLabel: "week-five",
        value: "weekFive",
        label: "Week 5",
        checked: weekFiveOption,
      },
      {
        id: "week-six-checkbox",
        datatestID: "week_six",
        ariaLabel: "week-six",
        value: "weekSix",
        label: "Week 6",
        checked: weekSixOption,
      },
      {
        id: "week-seven-checkbox",
        datatestID: "week_seven",
        ariaLabel: "week-seven",
        value: "weekSeven",
        label: "Week 7",
        checked: weekSevenOption,
      },
      {
        id: "week-eight-checkbox",
        datatestID: "week_eight",
        ariaLabel: "week-eight",
        value: "weekEight",
        label: "Week 8",
        checked: weekEightOption,
      },
      {
        id: "week-nine-checkbox",
        datatestID: "week_nine",
        ariaLabel: "week-nine",
        value: "weekNine",
        label: "Week 9",
        checked: weekNineOption,
      },
      {
        id: "week-ten-checkbox",
        datatestID: "week_ten",
        ariaLabel: "week-ten",
        value: "weekTen",
        label: "Week 10",
        checked: weekTenOption,
      },
      {
        id: "week-eleven-checkbox",
        datatestID: "week_eleven",
        ariaLabel: "week-eleven",
        value: "weekEleven",
        label: "Week 11",
        checked: weekElevenOption,
      },
      {
        id: "week-twelve-checkbox",
        datatestID: "week_twelve",
        ariaLabel: "week-twelve",
        value: "weekTwelve",
        label: "Week 12",
        checked: weekTwelveOption,
      },
      {
        id: "week-thirteen-checkbox",
        datatestID: "week_thirteen",
        ariaLabel: "week-thirteen",
        value: "weekThirteen",
        label: "Week 13",
        checked: weekThirteenOption,
      },
      {
        id: "week-fourteen-checkbox",
        datatestID: "week_fourteen",
        ariaLabel: "week-fourteen",
        value: "weekFourteen",
        label: "Week 14",
        checked: weekFourteenOption,
      },
      {
        id: "week-fifteen-checkbox",
        datatestID: "week_fifteen",
        ariaLabel: "week-fifteen",
        value: "weekFifteen",
        label: "Week 15",
        checked: weekFifteenOption,
      },
      {
        id: "week-sixteen-checkbox",
        datatestID: "week_sixteen",
        ariaLabel: "week-sixteen",
        value: "weekSixteen",
        label: "Week 16",
        checked: weekSixteenOption,
      },
    ]);
  }, [
    weekOneOption,
    weekTwoOption,
    weekThreeOption,
    weekFourOption,
    weekFiveOption,
    weekSixOption,
    weekSevenOption,
    weekEightOption,
    weekNineOption,
    weekTenOption,
    weekElevenOption,
    weekTwelveOption,
    weekThirteenOption,
    weekFourteenOption,
    weekFifteenOption,
    weekSixteenOption
  ]);


  // code to handle error messages occuring on submit.
  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (submitErrorMessage !== "") {
      setOpenSuccess(true);
    }
  }, [submitErrorMessage]);

  // Manage error display use states
  const [commentsErrorDisplay, setCommentsErrorDisplay] = useState();
  const [areasOfConcernErrorDisplay, setAreasOfConcernErrorDisplay] =
    useState();
  const [weeksErrorDisplay, setWeeksErrorDisplay] = useState();
  const [studentAndFacultyErrorDisplay, setStudentAndFacultyErrorDisplay] =
    useState(); // Since student And Faculty fields are locked and autofilled.. this should never be used.

  const testForNullSubmissions = (event) => {
    let tempAreaOfConcernError = false;
    let tempWeekSelectError = false;
    let tempCommentsError = false;

    // student and faculty fields
    if (
        (studentIrnInput !== "" && studentIrnInput !== null && studentIrnInput !== undefined) &&
        (studentFullNameInput !== "" && studentFullNameInput !== null && studentFullNameInput !== undefined) &&
        (facultyNameInput !== "" && facultyNameInput !== null && facultyNameInput !== undefined) &&
        (facultyEmailInput !== "" && facultyEmailInput !== null && facultyEmailInput !== undefined) &&
        (courseIdInput !== "" && courseIdInput !== null && courseIdInput !== undefined)
    ) {
        setStudentAndFacultyErrorDisplay(false);
    } else {
        event.preventDefault();
        handleScrollToTop();
        setStudentAndFacultyErrorDisplay(true);
    }

    // test for areas of concern
    if (
        concernCheckboxOne ||
        concernCheckboxTwo ||
        concernCheckboxThree ||
        concernCheckboxFour ||
        concernCheckboxFive
    ) {
        tempAreaOfConcernError = false;
        setAreasOfConcernErrorDisplay(false);
    } else {
        event.preventDefault();
        tempAreaOfConcernError = true;
        if (tempAreaOfConcernError) {
          const heading = document.getElementById("areasOfConcernHeader");
          heading.setAttribute('tabIndex', '-1');
          heading.focus();
        };
        setAreasOfConcernErrorDisplay(true);
    }

    // test for behavior weeks
    if (
        weekOneOption ||
        weekTwoOption ||
        weekThreeOption ||
        weekFourOption ||
        weekFiveOption ||
        weekSixOption ||
        weekSevenOption ||
        weekEightOption ||
        weekNineOption ||
        weekTenOption ||
        weekElevenOption ||
        weekTwelveOption ||
        weekThirteenOption ||
        weekFourteenOption ||
        weekFifteenOption ||
        weekSixteenOption
    ) {
        tempWeekSelectError = false;
        setWeeksErrorDisplay(false);
    } else {
        event.preventDefault();
        tempWeekSelectError = true;
        if (!tempAreaOfConcernError && tempWeekSelectError) {
          const heading = document.getElementById("weekSelectSection");
          heading.setAttribute('tabIndex', '-1');
          heading.focus();
        }
        setWeeksErrorDisplay(true); 
    }

    // check for comments
    if (comments !== "") {
        tempCommentsError = false;
        setCommentsErrorDisplay(false);
    } else {
        event.preventDefault();
        tempCommentsError = true;
        if (!tempAreaOfConcernError && !tempWeekSelectError && tempCommentsError) {
          const heading = document.getElementById("commentTextfieldSection");
          heading.setAttribute('tabIndex', '-1');
          heading.focus();
        };
        setCommentsErrorDisplay(true);
    }
  };

  // code to handle submission
  const handleSubmit = (event) => {
    testForNullSubmissions(event);

    if (
        (studentIrnInput !== "" && studentIrnInput !== null && studentIrnInput !== undefined) &&
        (studentFullNameInput !== "" && studentFullNameInput !== null && studentFullNameInput !== undefined) &&
        (facultyNameInput !== "" && facultyNameInput !== null && facultyNameInput !== undefined) &&
        (facultyEmailInput !== "" && facultyEmailInput !== null && facultyEmailInput !== undefined) &&
        (courseIdInput !== "" && courseIdInput !== null && courseIdInput !== undefined)
    ) {
      if (
        concernCheckboxOne ||
        concernCheckboxTwo ||
        concernCheckboxThree ||
        concernCheckboxFour ||
        concernCheckboxFive
      ) {
        if (
            weekOneOption ||
            weekTwoOption ||
            weekThreeOption ||
            weekFourOption ||
            weekFiveOption ||
            weekSixOption ||
            weekSevenOption ||
            weekEightOption ||
            weekNineOption ||
            weekTenOption ||
            weekElevenOption ||
            weekTwelveOption ||
            weekThirteenOption ||
            weekFourteenOption ||
            weekFifteenOption ||
            weekSixteenOption
        ) {
          if (comments !== "") {
            setOpenSuccess(false);
          } else {
            setSubmitErrorMessage(
              "Please include summary of what you want staff to know in comments section."
            );
          }
        } else {
          setSubmitErrorMessage(
            "Please select which week(s) behavior occurred.  Please do not submit if student hasn't met attendance requirements in week 1 of the course."
          );
        }
      } else {
        setSubmitErrorMessage(
          "Must select at least one option in check all that apply areas of concern."
        );
      }
    } else {
      setSubmitErrorMessage(
        "All Student and Faculty input fields must be filled."
      );
    //   setSubmitErrorMessage("Oops! Failed to retrieve faculty, student, or course info! Cannot submit form at this time.")
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  // API calls made to retrieve course offering information and student information.
  // Updating field states with proper data from endpoints.
  useEffect(() => {
    props.getCourseOfferingInfo(course_offering_id);
    props.getStudentProfile(profile_id);
  }, [course_offering_id, profile_id]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect for Updating input fields with faculty, student and course information.
  // Inpu fields are all required so cannot submit form if one of these fails. 
  useEffect(() => {
    if(props.courseOffering.courseCode !== null && props.courseOffering.courseCode !== undefined) {
        setCourseIdInput(`${props.courseOffering.courseCode}`)
    }
    if(
      props.facultyDemographics.firstName !== null && props.facultyDemographics.lastName !== null &&
      props.facultyDemographics.firstName !== undefined && props.facultyDemographics.lastName !== undefined) 
    {
        setFacultyNameInput(`${props.facultyDemographics.firstName} ${props.facultyDemographics.lastName}`)
    }
    if(props.facultyContacts.length !== 0) {
      for(let contact = 0; contact < props.facultyContacts.length; contact++) {
        if (props.facultyContacts[contact].type === "University") {
          setFacultyEmailInput(`${props.facultyContacts[contact].emailAddress}`)
        }
      }
    }
    if(props.studentProfile[0] !== null && props.studentProfile[0] !== undefined) {
        setStudentFullNameInput(`${props.studentProfile[0].firstName} ${props.studentProfile[0].lastName}`)
        setStudentIrnInput(`${props.studentProfile[0].issuerId}`)
    }
  }, [props.courseOffering, props.facultyDemographics, props.facultyContacts, props.studentProfile]) // eslint-disable-line react-hooks/exhaustive-deps


  // handle snack bar error message on initial render of page.
  // checks to see that faculty, student, and course info is present in student and faculty fields.
    useEffect(() => {
      if(Object.keys(props.facultyDemographics).length !== 0) {
        if(props.facultyDemographics.status === undefined) {
          if (
              (
                  props.courseOffering.status !== undefined &&
                  props.courseOffering.status !== 200
              )
              ||
              (
                  props.studentProfile.status !== undefined &&
                  props.studentProfile.status !== 200
              )
          ){
            setSubmitErrorMessage(
              <Typography>
                {<b>{`Something went wrong`}</b>}{<br/>}
                {`We couldn’t retrieve the student or course details.  `}
                {`Please try again – if this issue continues, `} 
                {`please reach out to Tech Support at (800) 800-3493.`}
              </Typography>
            );
          }  
        }
      }
    }, [props.courseOffering.status, props.studentProfile.status, props.facultyDemographics.status, props.facultyDemographics]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if(Object.keys(props.facultyDemographics).length !== 0) {
        if(props.facultyDemographics.status === undefined) {
          if(props.courseOffering.courseCode === null) {
            setSubmitErrorMessage(
              <Typography>
                {<b>{`Something went wrong`}</b>}{<br/>}
                {`We couldn’t retrieve the student or course details.  `}
                {`Please try again – if this issue continues, `} 
                {`please reach out to Tech Support at (800) 800-3493.`}
              </Typography>
            )
          }
        }
      }
    }, [props.courseOffering.courseCode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if( 
        props.facultyContacts.status !== undefined &&
        props.facultyContacts.status !== 200
      ) {
        setSubmitErrorMessage(
          <Typography>
            {<b>{`Something went wrong`}</b>}{<br/>}
            {`We couldn’t retrieve your university email address. `}
            {`Please try again – if this issue continues, `} 
            {`please reach out to Tech Support at (800) 800-3493.`}
          </Typography>
        )
      }
      if(props.facultyContacts.length !== undefined) {
        if (
            props.facultyContacts.length === 0
        ) {
          setSubmitErrorMessage(
            <Typography>
              {<b>{`Something went wrong`}</b>}{<br/>}
              {`We couldn’t retrieve your university email address.  `}
              {`Please try again – if this issue continues, `} 
              {`please reach out to Tech Support at (800) 800-3493.`}
            </Typography>
          )
        } else {
          let hasEmail = false;
          for(let contact = 0; contact < props.facultyContacts.length; contact++) {
            if (props.facultyContacts[contact].type === "University") {
              hasEmail = true;
            }
          }
          if(hasEmail === false) {
            setSubmitErrorMessage(
              <Typography>
                {<b>{`Something went wrong`}</b>}{<br/>}
                {`We couldn’t retrieve your university email address.  `}
                {`Please try again – if this issue continues, `} 
                {`please reach out to Tech Support at (800) 800-3493.`}
              </Typography>
            )
          }
        }
      }
    }, [props.facultyContacts.status, props.facultyContacts]) // eslint-disable-line react-hooks/exhaustive-deps
   
    const checkboxStyle = {
      position: 'relative',
      width: '24px',
      height: '24px',
      border: '1px solid #5E7079',
      borderRadius: '1px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '8px 16px 8px 0px',
  };

  const checkmarkStyle = {
      position: 'absolute',
      color: '#ffffff',
      fontSize: '24px',
      pointerEvents: 'none',
  };

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Box
        id="pageContainer"
        role="main"
        className={
          matches
            ? classes.pageContainer
            : tabletMatches
            ? classes.pageContainerTablet
            : classes.pageContainerMobile
        }
      >
        <Grid
          container
          md={12}
          sm={11}
          xs={10}
          className={
            matches
              ? classes.mainPageContent
              : tabletMatches
              ? classes.mainPageContentTablet
              : classes.mainPageContentMobile
          }
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            className={
              matches
                ? classes.linkContainer
                : tabletMatches
                ? classes.linkContainerTablet
                : classes.linkContainerMobile
            }
          >
            <Link
              id="backToDashboardLink"
              data-testid="back-to-dashboard-link"
              underline="none"
              className={classes.backToDashboardLink}
              href="/"
            >
              <img className={classes.arrowImage} src={BlackArrow} alt="" />
              <Typography>{`Course Dashboard`}</Typography>
            </Link>
          </Grid>

          <Grid
            item
            className={
              matches
                ? classes.pageHeaderContainer
                : classes.pageHeaderContainerMobile
            }
          >
            <Box>
              <Typography
                id="pageHeader"
                data-testid="page-header"
                component="h1"
                className={
                  matches
                    ? classes.pageHeader
                    : tabletMatches
                    ? classes.pageHeaderTablet
                    : classes.pageHeaderMobile
                }
              >
                {"Faculty Early Feedback Alert Submission"}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            className={
              matches
                ? classes.pageIntroContainer
                : classes.pageIntroContainerMobile
            }
          >
            <Box sx={{ maxWidth: "825px", marginBottom: "48px" }}>
              <Typography id="pageIntro" data-testid="page-intro">
                {`Early intervention is vital for student success.  Thank you for taking the time
                              to inform us of any potential issues this student may be facing.`}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            className={
              matches
                ? classes.mainInfoContainer
                : classes.mainInfoContainerMobile
            }
          >
            <Box 
              sx={
                matches
                  ? { marginBottom: "48px" }
                  : tabletMatches
                  ? { marginBottom: "48px" }
                  : { marginBottom: "16px" }
              }
            >
              <Typography
                id="studentAndFacultySubHeader"
                data-testid="student-and-faculty-sub-header"
                component="h2"
                className={
                  matches ? classes.subHeader : classes.subHeaderMobile
                }
              >
                {"Student and Faculty"}
              </Typography>
            </Box>

            <form action={`${process.env.REACT_APP_SUBMIT_FORM_URL}`} method="POST">
              <input type="hidden" name="orgid" value={`${process.env.REACT_APP_SUBMIT_FORM_ORGID}`} />
              <input type="hidden" name="retURL" value={`${process.env.REACT_APP_SUBMIT_FORM_RETURL}${profile_id}`} />
              {/* <input type="hidden" name="retURL" value={`https://local.qaols.phoenix.edu:8443/earlyalertsuccessmessage?studentProfileId=${profile_id}`} /> */}

              <Box className={classes.hide}>
                Subject:<input value="Faculty Early Alert" id="subject" maxlength="80" name="subject" size="20" type="text" /><br/>
                Record Type:<input type="hidden" id="recordType" name="recordType" value={salesforceWebFormRecordType} />
                Type:<input type="hidden" name="type" value="Faculty Early Alert" />
                Course Offering Number:<input value={course_offering_id} type="hidden" name="CourseofferingID__c" />
                IRN From Web:<input value={studentIrnInput} name="ADS_IRN__c" type="hidden" /><br/>
                Course ID:<input value={courseIdInput} maxlength="255" name="Course_ID__c" size="20" type="text" /><br/>
                Faculty Name:<input value={facultyNameInput} maxlength="80" name="EAS_Faculty_Name__c" size="20" type="text" /><br/>
                Faculty Email:<input value={facultyEmailInput} maxlength="80" name="EAS_Faculty_Email__c" size="20" type="text" /><br/>
                Student Conduct Issues:<input checked={concernCheckboxOne} name="EAS_Student_Conduct_Issues__c" type="checkbox" value="1" /><br/>
                Individual Participation:<input checked={concernCheckboxTwo} name="EAS_Individual_Participation__c" type="checkbox" value="1" /><br/>
                Individual Assignment:<input checked={concernCheckboxThree} name="EAS_Individual_Assignment__c" type="checkbox" value="1" /><br/>
                Teamwork:<input checked={concernCheckboxFour} name="EAS_Teamwork__c" type="checkbox" value="1" /><br/>
                Other Concerns:<input checked={concernCheckboxFive} name="EAS_Other_Concerns__c" type="checkbox" value="1" /><br/>
                Week:<select multiple="multiple" name="EAS_Week__c" title="Week">
                  <option value="1" selected={weekOneOption}>1</option>
                  <option value="2" selected={weekTwoOption}>2</option>
                  <option value="3" selected={weekThreeOption}>3</option>
                  <option value="4" selected={weekFourOption}>4</option>
                  <option value="5" selected={weekFiveOption}>5</option>
                  <option value="6" selected={weekSixOption}>6</option>
                  <option value="7" selected={weekSevenOption}>7</option>
                  <option value="8" selected={weekEightOption}>8</option>
                  <option value="9" selected={weekNineOption}>9</option>
                  <option value="10" selected={weekTenOption}>10</option>
                  <option value="11" selected={weekElevenOption}>11</option>
                  <option value="12" selected={weekTwelveOption}>12</option>
                  <option value="13" selected={weekThirteenOption}>13</option>
                  <option value="14" selected={weekFourteenOption}>14</option>
                  <option value="15" selected={weekFifteenOption}>15</option>
                  <option value="16" selected={weekSixteenOption}>16</option>
                </select><br/>
                Description:<textarea value={comments} name="description"></textarea><br/><br/>
              </Box>

            {tabletMatches ? (
              <Box
                sx={
                  matches
                    ? { width: "969px", display: "flex", flexDirection: "row", flexWrap: "nowrap" }
                    : { width: "740px", display: "flex", flexDirection: "row", flexWrap: "nowrap" }
                }
              >
                <Box
                  sx={
                    matches
                      ? { width: "609px", boxSizing: "border-box" }
                      : { width: "380px", boxSizing: "border-box" }
                  }
                  className={classes.textFieldsBox}
                >
                  {studentAndFacultyFields.map((field) => (
                    <TextField
                      id={field.id}
                      data-testid={field.datatestID}
                      aria-label={field.ariaLabel}
                      label={field.label}
                      value={field.inputVal}
                      disabled={true}
                      variant="filled"
                      className={classes.inputTextField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </Box>
                <Box
                  sx={{ maxWidth: "304px" }}
                  id="submittingEarlyAlertInfo"
                  data-testid="submitting-early-alert-info"
                >
                  <Typography className={classes.infoTextThree}>
                    {`It's expected that you have already tried to remediate the situation with the student
                                  before submitting an early alert.`}
                  </Typography>
                  <Typography className={classes.infoTextTwo}>
                    {`Do NOT submit an early alert if:`}
                  </Typography>
                  <ul>
                    <li
                      className={classes.infoTextOne}
                    >
                      {`The student hasn't met attendance requirements in week 1 of the course.`}
                    </li>
                    <li
                      className={classes.infoTextOne}
                    >
                      {`The student is receiving ADA accommodations from the University, and there is an issue/question with their 
                                    accommodations or disability.  Matters related to the student's disability can be referred directly to the 
                                    student's Disability Services Advisor or you can submit an ADA Referral.`}
                    </li>
                  </ul>
                </Box>
              </Box>
            ) : (
              <Box sx={{ maxWidth: "327px" }}>
                <Box
                  sx={{ width: "100%", marginBottom: "40px" }}
                  id="submittingEarlyAlertInfo"
                  data-testid="submitting-early-alert-info"
                >
                  <Typography className={classes.infoTextThree}>
                    {`It's expected that you have already tried to remediate the situation with the student
                                  before submitting an early alert.`}
                  </Typography>
                  <Typography className={classes.infoTextTwo}>
                    {`Do NOT submit an early alert if:`}
                  </Typography>
                  <ul>
                    <li
                      className={classes.infoTextOne}
                    >
                      {`The student hasn't met attendance requirements in week 1 of the course.`}
                    </li>
                    <li
                      className={classes.infoTextOne}
                    >
                      {`The student is receiving ADA accommodations from the University, and there is an issue/question with their 
                                    accommodations or disability.  Matters related to the student's disability can be referred directly to the 
                                    student's Disability Services Advisor or you can submit an ADA Referral.`}
                    </li>
                  </ul>
                </Box>
                <Box className={classes.textFieldsBoxMobile}>
                  {studentAndFacultyFields.map((field) => (
                    <TextField
                      id={field.id}
                      data-testid={field.datatestID}
                      aria-label={field.ariaLabel}
                      label={field.label}
                      value={field.inputVal}
                      disabled={true}
                      variant="filled"
                      className={classes.inputTextField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            <Box
              sx={
                matches
                  ? { width: "609px", boxSizing: "border-box" }
                  : tabletMatches
                  ? { width: "380px", boxSizing: "border-box" }
                  : { maxWidth: "327px", boxSizing: "border-box" }
              }
            >
              <Typography 
                id="areasOfConcernHeader"
                data-testid="areas-of-concern-sub-header"
                component="h2"
                className={classes.fieldsetLegend}
              >
                Areas of concern
              </Typography>
              <fieldset
                className={classes.checkboxFieldset}
              >
                <legend data-testid="check-all-that-apply-instructions" className={classes.fieldsetLabel}>Check all that apply: (required)</legend>
                <ul style={{ marginTop: "20px", padding: "0px" }}>
                  {checkAllThatApplyOptions.map((option) => (
                    <li
                      className={classes.checkboxContainer}
                    >
                      <div>
                        <div
                          id={option.id}
                          data-testid={option.datatestID}
                          style={{...checkboxStyle, backgroundColor: option.checked ? '#001823' : '#ffffff', }}
                          onClick={() => handleCheckAllThatApplyChange(option.value)}
                          role="checkbox"
                          aria-checked={option.checked}
                          aria-label={option.ariaLabel}
                          tabIndex={0}
                          onMouseDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                handleCheckAllThatApplyChange(option.value)
                              }
                          }}
                        >
                          {option.checked && <CheckSharpIcon style={{...checkmarkStyle, visibility: option.checked ? 'visible' : 'hidden'}} />}
                        </div>
                      </div>
                      <div>
                        <label
                          for={option.id}
                          className={classes.checkboxLabel}
                        >
                          {option.label}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
                <Box
                  data-testid="concern-option-select-error"
                  className={
                    areasOfConcernErrorDisplay
                      ? classes.errorbox
                      : classes.hide
                  }
                  sx={
                    matches
                      ? { width: "609px", boxSizing: "border-box" }
                      : tabletMatches
                      ? { width: "380px", boxSizing: "border-box" }
                      : { maxWidth: "327px", boxSizing: "border-box" }
                  }
                >
                  <ErrorIcon
                    sx={{
                      color: "#B21F13",
                      height: "16px",
                      width: "16px",
                      padding: "9px 7px 9px 18px",
                    }}
                  />
                  <Typography
                    className={classes.errorboxText}
                  >{`This field requires a value`}</Typography>
                </Box>
              </fieldset>

              <fieldset 
                id="weekSelectSection"
                className={classes.checkboxFieldset}
              >
                <legend 
                  style={{ marginBottom: "20px" }} 
                  data-testid="week-checkboxes-instruction" 
                  className={classes.fieldsetLabel}
                >
                  {`Which week(s) have these behaviors occurred: (required)`}
                </legend>
                <ul 
                  className={classes.checkboxListContainer}
                >
                  {weekOptions.map((option) => (
                    <li
                      style={
                        matches
                        ? { flexBasis: "20%" }
                        : tabletMatches
                        ? { flexBasis: "40%" }
                        : { flexBasis: "40%" }
                      }
                      className={classes.checkboxContainer}
                    >
                      <div>
                        <div
                          id={option.id}
                          data-testid={option.datatestID}
                          style={{...checkboxStyle, backgroundColor: option.checked ? '#001823' : '#ffffff', }}
                          onClick={() => handleWeekOptionsChange(option.value)}
                          role="checkbox"
                          aria-checked={option.checked}
                          aria-label={option.ariaLabel}
                          tabIndex={0}
                          onMouseDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                handleWeekOptionsChange(option.value)
                              }
                          }}
                        >
                          {option.checked && <CheckSharpIcon style={{...checkmarkStyle, visibility: option.checked ? 'visible' : 'hidden'}} />}
                        </div>
                      </div>
                      <div>
                        <label
                          for={option.id}
                          className={classes.checkboxLabel}
                        >
                          {option.label}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
                <Box
                  data-testid="week-select-error"
                  className={
                    weeksErrorDisplay ? classes.errorbox : classes.hide
                  }
                  sx={
                    matches
                      ? { maxWidth: "609px", marginTop: "-40px", boxSizing: "border-box" }
                      : tabletMatches
                      ? { maxWidth: "380px", marginTop: "-40px", boxSizing: "border-box" }
                      : { maxWidth: "327px", marginTop: "-40px", boxSizing: "border-box" }
                  }
                >
                  <ErrorIcon
                    sx={{
                      color: "#B21F13",
                      height: "16px",
                      width: "16px",
                      padding: "9px 7px 9px 18px",
                    }}
                  />
                  <Typography
                    className={classes.errorboxText}
                  >{`This field requires a value`}</Typography>
                </Box>
              </fieldset>
            </Box>

            <Box
              id="commentTextfieldSection"
              sx={
                matches
                  ? { width: "609px", marginBottom: "24px", boxSizing: "border-box" }
                  : tabletMatches
                  ? { width: "380px", marginBottom: "24px", boxSizing: "border-box" }
                  : { maxWidth: "327px", marginBottom: "24px", boxSizing: "border-box" }
              }
            >
              <Typography
                id="commentsInstructions"
                data-testid="comments-instruction"
                className={classes.infoTextTwo}
              >
                {`Please include a summary of what you want the staff to know about this student,
                              plus any details on interventions or actions you have taken to date. (required)`}
              </Typography>
            </Box>
            <Box sx={
                matches
                  ? { width: "609px", marginBottom: "64px", boxSizing: "border-box" }
                  : tabletMatches
                  ? { width: "380px", marginBottom: "64px", boxSizing: "border-box" }
                  : { maxWidth: "327px", marginBottom: "64px", boxSizing: "border-box" }
              }
            >
              <Typography
                data-testid="disclosure-warning"
                className={classes.infoTextTwoImportant}
              >
                {`Please DO NOT disclose any details regarding ADA or Title IX in this form.`}
              </Typography>
            </Box>
            {commentsErrorDisplay ? (
              <Box
                sx={
                  matches
                    ? { width: "609px", marginBottom: "64px", boxSizing: "border-box" }
                    : tabletMatches
                    ? { width: "380px", marginBottom: "64px", boxSizing: "border-box" }
                    : { maxWidth: "327px", marginBottom: "64px", boxSizing: "border-box" }
                }
              >
                <TextField
                  id="commentsBox"
                  data-testid="comments-text-area"
                  aria-label="comments text box"
                  aria-labelledby="commentsInstructions"
                  multiline="true"
                  minRows="4"
                  maxRows="4"
                  label="Summary"
                  variant="filled"
                  className={classes.commentsTextArea}
                  onChange={(event) => setComments(event.target.value)}
                />
                <Box
                  data-testid="comments-textbox-error"
                  className={classes.errorboxComments}
                  sx={
                    matches
                      ? { width: "100%", boxSizing: "border-box" }
                      : tabletMatches
                      ? { width: "100%", boxSizing: "border-box" }
                      : { width: "100%", boxSizing: "border-box" }
                  }
                >
                  <ErrorIcon
                    sx={{
                      color: "#B21F13",
                      height: "16px",
                      width: "16px",
                      padding: "9px 7px 9px 18px",
                    }}
                  />
                  <Typography
                    className={classes.errorboxText}
                  >{`This field requires a value`}</Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={
                  matches
                    ? { width: "609px", marginBottom: "64px" }
                    : tabletMatches
                    ? { width: "380px", marginBottom: "64px" }
                    : { maxWidth: "327px", marginBottom: "64px" }
                }
              >
                <TextField
                  id="commentsBox"
                  data-testid="comments-text-area"
                  aria-label="comments-text-box"
                  aria-labelledby="commentsInstructions"
                  multiline="true"
                  minRows="4"
                  maxRows="4"
                  label="Summary"
                  variant="filled"
                  className={classes.commentsTextArea}
                  onChange={(event) => setComments(event.target.value)}
                />
              </Box>
            )}
                  {/* <input type="hidden" name="debug" value="1"></input> */}
                  <Box sx={{ marginBottom: "152px" }}>
                      <Button
                          type="submit"
                          name="submit"
                          id="submit_button"
                          data-testid="submit-button"
                          aria-label="submit-early-alert-button"
                          variant="outlined"
                          className={classes.submitButton}
                          onClick={(event) => {handleSubmit(event)}}
                      >
                          {"Submit"}
                      </Button>
                  </Box>
              </form>
          </Grid>
        </Grid>
      </Box>
      {openSuccess &&
        (submitErrorMessage !== ""
          ? renderSnackbar("error", submitErrorMessage)
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    facultyDemographics: state.demographics,
    facultyContacts: state.facultyContacts,
    courseOffering: state.courseOffering,
    studentProfile: state.studentProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getCourseOfferingInfo: (courseOfferingId) => dispatch(getCourseOfferingInfo(courseOfferingId)),
      getStudentProfile: (profileId) => dispatch(getStudentProfile(profileId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EarlyAlertSubmissionPage);