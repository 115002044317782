import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import styles from "../styles";

function ActionNeeded(props) {
  const useStyles = styles;
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const modalContentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (modalContentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          modalContentRef.current;

        const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 5;
        setScrolledToBottom(isScrolledToBottom);
      }
    };

    const modalContentElement = modalContentRef.current;
    if (modalContentElement) {
      modalContentElement.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (modalContentElement) {
        modalContentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleKeyDown = (event) => {
    if (modalContentRef.current) {
      switch (event.key) {
        case "ArrowUp":
          modalContentRef.current.scrollBy({
            top: -220,
            behavior: "smooth",
          });
          break;
        case "ArrowDown":
          modalContentRef.current.scrollBy({
            top: 220,
            behavior: "smooth",
          });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Grid
        container
        role="dialog"
        aria-labelledby="action-needed-modal-title"
        className={classes.actionContainer}
        component={Paper}
        elevation={2}
        xs={12}
      >
        <Box
          className={
            matches
              ? classes.closeButtonContainer
              : classes.mobileCloseButtonContainer
          }
          // logic for disabling will need to be replaced when we have db set up tracking acknowledgement
          onClick={scrolledToBottom ? () => props.setOpen(false) : null} 
          tabIndex={scrolledToBottom ? 0 : -1} 
          onKeyDown={(event) => {
            if (
              scrolledToBottom &&
              (event.key === "Enter" || event.key === " ")
            ) {
              event.preventDefault();
              props.setOpen(false);
            }
          }}
          role="button"
          aria-label="Close"
          aria-disabled={!scrolledToBottom}
          style={{
            cursor: scrolledToBottom ? "pointer" : "not-allowed", 
            opacity: scrolledToBottom ? 1 : 0.5, 
          }}
        >
          <Typography className={classes.closeButtonText}>Close</Typography>
        </Box>
        <Box
          className={classes.mainPopUpContent}
          ref={modalContentRef}
          style={{ overflowY: "auto", outline: "none" }}
        >
          <Box>
            <Typography className={classes.titleText} component="h2" id="action-needed-modal-title">
              Action needed: New student accommodation
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.greetingText}>
              Hello DAJA MCCLEVE,
            </Typography>
          </Box>
          <Box className={classes.infoContainer}>
            <Typography className={classes.boldText}>
              Regarding: &nbsp;<Typography>David Jimbob</Typography>
            </Typography>
            <Typography className={classes.boldText}>
              IRN: &nbsp;<Typography>123456</Typography>
            </Typography>
            <Typography className={classes.boldText}>
              Course: &nbsp;<Typography>Course/123</Typography>
            </Typography>
            <Typography className={classes.boldText}>
              Course Start Date: &nbsp;<Typography>5/24/2024</Typography>
            </Typography>
            <Typography className={classes.boldText}>
              Course End Date: &nbsp;<Typography>6/24/2024</Typography>
            </Typography>
          </Box>
          <Box style={{ marginBottom: "24px" }}>
            <Typography>
              This student is approved for the following accommodation(s) under
              University policy.
            </Typography>
          </Box>

          <Box style={{ marginBottom: "24px" }}>
            <Typography component="h3" className={classes.subHeadingText}>
              Approved Accommodations:
            </Typography>
          </Box>

          <Box style={{ marginBottom: "24px" }}>
            <Typography className={classes.accomText}>
              Extra time for testing; Additional time individual assignments
            </Typography>
          </Box>

          <Box style={{ marginBottom: "24px" }}>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Extra time:</span> Additional
              time for completing individual assignments: The student is to be
              allowed a minimum of 1.5X to complete individual assignments.
            </Typography>
          </Box>

          <Typography style={{ marginBottom: "24px" }}>
            a) Individual assignments are only those individual projects or
            papers due during the class, and does not extend to discussion posts
            or group assignments.
          </Typography>

          <Typography style={{ marginBottom: "24px" }}>
            b) As a general guideline, the student should be allowed a minimum
            of 1.5 times (4 days) to complete individual assignments without
            penalty. Students receiving this accommodation have until the end of
            the course, plus the additional time indicated to submit individual
            assignments. For example, in a 5 weeks course, a student with 1.5
            times the normal time to complete assignments will not be required
            to turn in assignments until four (4) days following the end of the
            course.
          </Typography>

          <Typography style={{ marginBottom: "24px" }}>
            c) The student may qualify to receive additional time beyond the
            class end date and can be granted an Incomplete (In Progress
            Extension -IX grade) by the Student Accommodations Office. The
            student must meet the regular campus attendance requirements, and
            the IX grade does not include discussion posts or group assignments.
          </Typography>

          <Typography style={{ marginBottom: "24px" }}>
            d) In the event that the student will be taking an IX grade, I will
            send a notice to you and the student with the IX deadline date,
            before the last day of class or within 1 week of the class ending.
            The IX grade differs from the I Grade (incomplete) in the following
            ways: The IX grade will be provided regardless of academic standing.
            There is no percentage deduction for any individual work completed
            during the approved IX time frame Incomplete Grade Contract is not
            required
          </Typography>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`Faculty may access information about accommodations through the `}
              <Button
                href="https://multimedia.phoenix.edu/faculty-resources/resources/accommodation-dictionary/"
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Accommodation Dictionary`}
              </Button>
              {` available on the Faculty Resource Center. Students will access the `}
              <Button
                href="https://my.phoenix.edu/kb/article/Student-Accommodations-Dictionary-SAO"
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Student Accommodation Dictionary`}
              </Button>
              {` with the same information in the My
            Phoenix Help Topics.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`The `}
              <Button
                href="https://multimedia.phoenix.edu/faculty-resources/wp-content/uploads/2024/08/accommodation-dictionary-production-9-13-2024.pdf#page=17"
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Faculty Responsibilities – ADA`}
              </Button>
              {` outlines your responsibilities. Please review in the Resource section of the Accommodation Dictionary.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`Please also review `}
              <Button
                href="https://multimedia.phoenix.edu/faculty-resources/wp-content/uploads/2024/08/accommodation-dictionary-production-9-13-2024.pdf#page=21"
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Setting Up Accommodations in Blackboard`}
              </Button>
              {`, which will help you designate the students who receive accommodations. Additional resources for managing accommodated students in Blackboard are also provided.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`All information pertaining to the student’s accommodations must remain confidential. The reason the student qualifies for accommodations is not disclosed to faculty and is not information faculty needs to know to implement accommodations in a course.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`If you have any questions regarding this information, please do not hesitate to contact us for more information. Have a great day and thank you for your continued efforts to assist our students.`}
            </Typography>
          </Box>

          <Box className={classes.thankyouContainer}>
            <Typography> Thank you, </Typography>
            <Typography className={classes.justBold}>
              Your Student Accommodations Team
            </Typography>
          </Box>
        </Box>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Button
            className={classes.gotItButton}
            style={{
              alignSelf: "center",
              borderRadius: "30px",
              padding: `${matches ? "8px 32px" : "2px 16px"}`,
              margin: "30px 16px 10px 0px",
              width: matches ? "253px" : "200px",
              height: "51px",
              backgroundColor: scrolledToBottom ? "#DC3727" : "#B0B0B0",
              color: scrolledToBottom ? "white" : "#6D6D6D",
              textTransform: "none",
              cursor: scrolledToBottom ? "pointer" : "not-allowed",
              "&:hover": {
                backgroundColor: scrolledToBottom ? "#C62828" : "#B0B0B0",
              },
            }}
            aria-label="Got it, acknowledgement"
            variant="contained"
            onClick={() => props.setOpen(false)}
            disabled={!scrolledToBottom}
          >
            Got it!
          </Button>
        </div>
      </Grid>
    </>
  );
}

export default ActionNeeded;
