import { useState, useEffect } from "react";
import styles from "../styles";
import { Link } from "@mui/material";
import { Typography, useMediaQuery, Grid } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import BlackArrow from "../../../images/BlackArrow.svg";
import facultyPortalStyles from "../../../styles";
import SolicitationsContainer from "./newSolicitationCard/SolicitationsContainer";
import CoursePrefCard from "../../coursePreferences/components/CoursePrefCard";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { ThemeProvider } from "@material-ui/core/styles";

const Solicitations = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const facultyClasses = facultyPortalStyles();
  document.title = "Faculty Portal Solicitations";

  const [status, setStatus] = useState();
  const [message, setMessage] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleMessageDisplay = (response) => {
    setStatus(response.status);
    setMessage(response.message);
  };

  useEffect(() => {
    if (props.errorMessages.length > 1) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]);

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <div
        className={
          matches
            ? facultyClasses.pageContainer
            : facultyClasses.pageContainerMobile
        }
        role="main"
      >
        <Grid
          container
          md={12}
          sm={11}
          xs={8}
          className={matches ? classes.linkBox : classes.linkBoxMobile}
        >
          <Grid item md={12} sm={11} xs={10} className={classes.backContainer}>
            <Link
              underline="none"
              sx={{
                color: "#001823",
                display: "flex",
                justifyContent: "space-between",
                "&hover": {
                  cursor: "pointer",
                },
              }}
              href="/"
            >
              <img className={classes.arrowImage} src={BlackArrow} alt="" />
              <Typography>{`Course Dashboard`}</Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sm={9}
          xs={12}
          className={
            matches ? classes.mainContainer : classes.mainContainerMobile
          }
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            className={matches ? classes.pageTitle : classes.pageTitleMobile}
          >
            <Typography component="h1" variant={matches ? "h1" : "h4"}>
              {`Solicitations`}
            </Typography>
          </Grid>
          {matches ? (
            <Grid item md={12} sm={11} className={classes.mainContent}>
              <Grid item md={12} sm={10} className={classes.cardContainer}>
                <SolicitationsContainer
                  handleMessageDisplay={handleMessageDisplay}
                />
              </Grid>
              <Grid item md={4} sm={10}>
                <CoursePrefCard
                  data-testid="coursePreference"
                  title="COURSE PREFERENCES"
                  content="Keep your preferences up-to-date to improve your solicitation results."
                  linkName="Edit preferences"
                  link="/coursepreferences"
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <SolicitationsContainer />
              <Grid item xs={12}>
                <CoursePrefCard
                  data-testid="coursePreference"
                  title="COURSE PREFERENCES"
                  content="Improve solicitation results, by keeping your teaching preferences up to date."
                  linkName="Edit preferences"
                  link="/coursepreferences"
                />
              </Grid>
            </>
          )}
        </Grid>
        {status && status === 200
          ? renderSnackbar("success", message)
          : message && renderSnackbar("error", message)}
      </div>
      {openSuccess &&
        (props.errorMessages.length > 1
          ? renderSnackbar("error", "Oops! Something went wrong.")
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    responseSolicitation: state.responseSolicitation,
    errorMessages: state.errorMessages,
  };
};

export default connect(mapStateToProps)(Solicitations);
