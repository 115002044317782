import styles from "./Links.styles";
import { Drawer, useMediaQuery } from "@material-ui/core";
import { Link, Box } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import SideBar from "../../SideBar";
import MenuIcon from "@material-ui/icons/Menu";

const MenuLink = () => {
  // Allow for MUI styling
  const classes = styles();

  // Utilize useMediaQuery to create UI view for Mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // Apply useState to set Drawer display
  const [drawerDisplay, setDrawerDisplay] = useState(false);

  // Function to change state of Drawer
  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerDisplay(!drawerDisplay);
  };

  return (
    <Box
      className={matches ? classes.linkContainerMobile : classes.linkContainer}
      // role="menu"
    >
      <Link
        aria-labelledby="quickLinks"
        aria-expanded={drawerDisplay}
        className={classes.linkButton}
        component="button"
        underline="none"
        color="inherit"
        onClick={toggleDrawer}
        id="quickLinks"
        data-testid="quickLinks"
        aria-label={matches ? "My Phoenix Faculty Navigation Menu" : null}
      >
        <MenuIcon titleAccess="" role="presentation" style={{width: "30px", height: "30px"}} />
        {matches ? null : `Quick Links`}
        <Drawer anchor="right" open={drawerDisplay} onClose={toggleDrawer}>
          <SideBar onClose={toggleDrawer} />
        </Drawer>
      </Link>
    </Box>
  );
};

export default MenuLink;
