import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  linkContainer: {
    height: "100%",
    // width: "73px",
    display: "flex",
    justifyContent: "space-evenly",
    margin: "0px 0px 0px 10px",
  },

  // Mobile View
  linkContainerMobile: {
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    margin: "0px 0px 0px 10px",
  },

  // Universal for Desktop and Mobile View
  linkButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
  },
});
