import { makeStyles } from "@material-ui/core";
import BackgroundPhoenix from "../../images/background.png";

export default makeStyles({
  // Desktop View
  pageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "400px 450px",
    backgroundPosition: "25% 0%",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  mainPageContent: {
    maxWidth: "1232px", // Maximum width for large screens
    width: "86%", // Leaves a 7% margin on each side (100% - 2 * 7%)
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "2%",
    marginLeft: "8%",
    marginBottom: "48px",
    height: "24px",
    width: "100%",
  },
  pageHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "32px",
  },
  pageHeader: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "48px",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  pageIntroContainer: {
    width: "969px",
  },
  mainInfoContainer: {
    width: "969px",
  },
  textFieldsBox: {
    display: "flex",
    flexDirection: "column",
    width: "513px",
    marginRight: "56px",
    marginBottom: "40px",
    boxSizing : "borderBox",
  },
  saveIcon: {
    color: "#000000 !important",
  },
  saveIconDisabled: {
    color: 'gray !important',
  },
  iconButton: {
    padding: "5px",
    margin: "0px",
    '&:focus': {
      border: "2px solid black",
    },
  },

  // Tablet view
  pageContainerTablet: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "10% 0%",
    overflow: "hidden",
  },
  mainPageContentTablet: {
    width: "740px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerTablet: {
    width: "100%",
    marginTop: "40px",
    marginBottom: "48px",
    marginLeft: "5%",
  },
  pageHeaderTablet: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "40px",
  },
  textFieldsBoxTablet: {
    display: "flex",
    flexDirection: "column",
    width: "380px",
    marginRight: "56px",
    marginBottom: "40px",
  },
  textContent: {
    marginBottom: "14px",
  },
  dateText: {
    margin: "4px 4px 0px 6px",
    color: "#000304",
  },
  tableHeaderContainer:{
    justifyContent: "space-between",
    marginBottom: "40px",
  },
  tableHeaderText: {
    padding: "24px",
    marginBottom: "24px"
  },
  classButtonContainer: {
    padding: "24px",
    marginBottom: "24px",
    marginRight: "-16px",
  },
  classButtonContainerTablet: {
    padding: "24px",
    marginBottom: "24px",
    marginRight: "-16px",
  },
  classButtonContainerMobile: {
    padding: "0 24px 0 24px",
    marginBottom: "24px",
    marginRight: "-16px",
    width: "100%",
  },
  subHeaderContainer: {
    display: "flex",
    marginBottom: "64px",
    flexDirection: "column",
    justifyContent: "center"
  },
  courseText: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: "4px",
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '32px',
    marginBottom: "4px",
    lineHeight: "1.25"
  },
  subHeadingText: {
    fontWeight: 'bold',
    fontSize: "18px",
  },
  courseCardTableHeader: {
    background: "#1A3D4D",
  },
  tableText: {
    color: "#FFFFFF !important",
    zIndex: 1,
  },
  justBold: {
    fontWeight: "bold"
  },
  tablesContainer:{
    marginBottom: "40px"
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '& .MuiInputBase-input': {
        color: 'black', 
      },
    },
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
    width: "100%",
    minWidth: "200px",
    backgroundColor:"#FFFFFF"
  },
  textFieldGray: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '& .MuiInputBase-input': {
        color: 'black', 
      },
    },
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
    width: "100%",
    minWidth: "200px",
    backgroundColor:"#E3E6E7"
  },
  select: {
    '& .MuiSelect-root': {
      color: 'black',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
  },
  selectGray: {
    '& .MuiSelect-root': {
      color: 'black',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
    backgroundColor:"#E3E6E7"
  },
  noStudentsText: {
    // marginLeft:"50px"
    marginLeft: "2%"
  },
  emptyStateContainer: {
    backgroundColor: '#ffffff',
    padding: "50px 0px 50px 0px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    margin: "2% 0 6% 0",
    width: '91%', 
    borderBottom: 'none',
    boxShadow: 'none',     
    textDecoration: 'none',
  },
  tablePadding: {
    padding:"0 24px 0 24px",
  },
  rowContainer: {
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #D2D7D9', 
    },
  },
  newAccomContainer: {
    backgroundColor: "#FFF1E4",
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #D2D7D9', 
    },
  },
  mainContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: "2% 0 3% 0",
    width: '91%', 
  },
  newFont: {
    fontSize: "16px"
  },  
  warningIcon: {
    color: "#E06C00",
  },
  linkText: {
    color: "#000000",
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    textTransform: 'capitalize',
    '&:focus': {
      border: '2px solid black',
    },
  },
  tableCell: {
    width: "90px", 
  },
  tableContainer: {
    maxHeight: '800px',
    overflowY: 'auto',
  },
  actionContainer: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
  },
  mainPopUpContent: {
    height: "600px",
    overflowY: "auto", // Vertical scroll

    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    scrollbarWidth: 'thin', // Firefox
    scrollbarColor: '#888 #f1f1f1', // Firefox
  },  
  accomText: {
    color:"#DC3727",
    fontStyle: 'italic',
  },
  thankyouContainer: {
    display: "flex",
    flexDirection: "column"
  },
  actionModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  modalContent: {
    backgroundColor: 'white',
    outline: 'none',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    width: '80vw', // Increased width to 80% of the viewport width
    maxWidth: '1000px', // Adjust the max width to a larger value if needed
    maxHeight: '90vh', 
    overflow: 'auto',
  },
  greetingText: {
    margin: "30px 0 30px 0"
  },
  // courseText: {
  //   fontWeight: 'bold',
  //   fontSize: '24px',
  //   marginBottom: "4px",
  // },
  boldText: {
    fontWeight: 'bold',
    display: "flex",
  },
  infoContainer:{
    margin: "0 0 30px 0"
  },
  buttonContainer: {
    display:"flex",
    alignItems:"flex-end"
  },
  closeButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "5px 0 25px 0",
    cursor: "pointer"
  },
  mobileCloseButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer"
  },
  closeButtonText: {
    fontWeight: "500",
    borderBottom: "3px solid #000",
    padding: "0px 0px 0.5px 0"
  },
  gotItButton: {
    "&:focus": {
      outline: "none", // Remove default outline
      border: "2px solid black",
    },
  },
    stickyColumn: {
      position: 'sticky',
      left: 0,
      zIndex: 20,
      backgroundColor: '#ffffff', 
    },
    newAccomStickyColumn: {
      position: 'sticky',
      left: 0,
      zIndex: 20,
      backgroundColor: "#FFF1E4",
    },
    stickyColumnHeader: {
      position: 'sticky',
      left: 0,
      zIndex: 30,
      backgroundColor: '#1A3D4D',
      color: '#ffffff', 
    },
    tableRow: {
      position: 'relative',
    },

  // Mobile View
  pageContainerMobile: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "317px 396px",
    backgroundPosition: "center 2%",
    overflow: "hidden",
  },
  mainPageContentMobile: {
    width: "327px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerMobile: {
    width: "100%",
    margin: "40px 0px",
    marginLeft: "8%"
  },
  pageHeaderContainerMobile: {
    // for both mobile and tablet
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "64px",
  },
  pageHeaderMobile: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    marginTop: "20px",
    marginBottom: "8px"
  },
  pageIntroContainerMobile: {
    // for both mobile and tablet
    width: "100%",
  },
  mainInfoContainerMobile: {
    // for both mobile and tablet
    width: "100%",
  },
  textFieldsBoxMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "40px",
  },

  // Universal (used in desktop, tablet and mobile)
  arrowImage: {
    width: "27px",
    height: "24px",
    marginRight: "10px",
  },
  backToDashboardLink: {
    display: "flex",
    marginLeft: "2%",
    color: "#001823",
    "&hover": {
      cursor: "pointer",
      color: "#001823",
    },
  },
  labelInputTextField:{
    width: "513px", 
    background:"#ffffff",
    height: "150px",
    border: "1px solid",
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
  },

  labelInputMobileTextField:{
    width: "100%", 
    //marginBottom: "64px", 
    borderRadius: "4px",
    // width: "969px",

     height: "150px",
    background: "#ffffff",
    border: "1px solid",
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
   },

  inputTextFieldLocked:{
    width: "100%",
    background: "#E3E6E7",
    border: "1px solid",
    marginBottom: "24px",
    borderRadius: "4px",
    '& .MuiFormLabel-root.Mui-disabled': {
      opacity: "1",
      color: "#000000",
    },
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: "1",
      color: "#000000"
    },
  },
  numberedItem: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00638C",
    color: "white",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    lineHeight: "24px",
    textAlign: "center",
    marginRight: "8px",
    fontWeight: "bold",
    fontSize: "12px"
  },

  inputTextField: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid",
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
    color: "#000000"
  },
  menuItem: {
    top :"56px",
    padding: "24px, 32px, 24px, 32px",
    height:"56px",
  },
  commentsTextArea: {
    width: "100%",
    height: "112px",
    border: "1px solid",
    borderRadius: "4px",
    background: "#ffffff",
    marginTop: "-40px",
  },
  commentsTextAreaError: {
    width: "100%",
    height: "112px",
    border: "1px solid",
    borderRadius: "4px 4px 0 0",
    borderColor: "#B21F13",
    borderBottom: "none",
    background: "#ffffff",
    marginTop: "-40px",
    "&::placeholder": {
      color: "#B21F13",
      fontWeight: "700",
      padding: "12px 0 0 12px",
    },
  },
  submitButton: {
    width: "240px",
    height: "56px",
    color: "#ffffff",
    background: "#DB3725",
    border: "none",
    borderRadius: "32px",
    textTransform: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
      background: "#DB3725",
    },
    "&.Mui-disabled": {
        backgroundColor: "#E3E6E7",
        color: "#5E7079",
      },
  },
  errorbox: {
    display: "flex",
    height: "34px",
    backgroundColor: "#FBEBEA",
    border: "1px solid",
    borderColor: "#B21F13",
    borderRadius: "4px",
  },
  errorboxComments: {
    display: "flex",
    maxWidth: "513px",
    height: "34px",
    backgroundColor: "#FBEBEA",
    border: "1px solid",
    borderColor: "#B21F13",
    borderRadius: "0 0 4px 4px",
  },
  errorboxText: {
    color: "#B21F13",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "18px",
    padding: "9px 0 7px 0",
  },
  hide: {
    display: "none",
  },
  menuItemFocused: {
    '&:focus': {
      border: '2px solid black',
    },
  },
  inputTextField: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid",
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
    color: "#000000"
  },
  popUpLinkText: {
    color: "#30718d",
    textDecoration: "underline",
    backgroundColor: "transparent",
    padding: 0,
    fontSize: "16px",
    fontStyle:"roboto",
    minWidth: "auto",
    marginTop: "-2px",
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    '&:focus': {
      border: '2px solid black',
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  textBlock: {
    marginBottom: "24px"
  },
  rowDisplay: {
    marginRight: "24px"
  },
});
