import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  alertBox :{
   minHeight: "80px",
    display: "flex",
    padding: "16px 24px",
    background: "#FFF1E4",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    marginTop: "15px",
    borderRadius: "3px"
  },
  alertTextContainer: {
    margin: "0px 0px 0px 15px",
    maxWidth: "500px",
    // flexDirection: 'column',
    // display : "flex"
  },
  alertHeader :{
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  headerContainer: {
    // display : "flex",
    // alignItems: "center",
    // marginBottom: "12px",
    display : "flex",
    flexDirection : "column",
    marginLeft : "8px"
    
  },
 alerText: {
    fontSize: "16px",
    lineHeight: "20px",
    color:  "#3A4F59"
  },
alertIcon :{
     marginRight: "5px",
     width :"22px",
     height: "22px",
    //  backgroundColor : "#E06C00"
    // marginTop: "3px",
},
infoBox :{
    width: "760px",
    height: "80px",
    display: "flex",
    padding: "10px 0px",
    background: "#D6F3FF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    marginTop: "15px",
    borderRadius: "3px"
},
infoHeader :{
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#000000",
},

}));

export default useStyles;
