import { makeStyles, useMediaQuery, Box } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import LogoutLink from "./LogoutLink";
import EmailLink from "./EmailLink";
import MenuLink from "./MenuLink";

const useStyles = makeStyles({
  // Desktop View
  links: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "4%",
    gap: "7px",
  },

  // Mobile View
  mobileLinks: {
    display: "flex",
    alignItems: "center",
    margin: "0px 15px 0px 0px",
  },
});

const LinkBox = () => {
  // Allow for application of MUI styling
  const classes = useStyles();

  // Utilize useMediaQuery to create mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return matches ? (
    <Box className={classes.mobileLinks}>
      <MenuLink />
      <EmailLink />
      <LogoutLink />
    </Box>
  ) : (
    <Box className={classes.links} role="navigation">
      <MenuLink />
      <EmailLink />
      <LogoutLink />
    </Box>
  );
};

export default LinkBox;
