import { createTheme } from "@mui/material/styles";

const facultyPortalTheme = createTheme({
  palette: {
    primary: {
      main: "#DC3727",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#3A4F59",
      contrastText: "#FFFFFF",
    },
    black: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    // info: {
    //   main: ""
    // },
    // error: {
    //   main: ""
    // },
    // success: {
    //   main: ""
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 992,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    // variants mapped to <p> tag
    body1: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "15px",
      lineHeight: "21px",
    },
    subtitle1: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "uppercase",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "21px",
      lineHeight: "24px",
      color: "#000304",
    },
    subtitle3: {
      // footer, header, quicklinks cards,
      color: "#000304",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      lineHeight: "18px",
    },
    subtitle4: {
      color: "#000304",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "18px",
    },
    muiButtonText: {
      height: "19px",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "19px",
      textTransform: "none",
      color: "white", //"#3A4F59",
    },
    muiDisabledButtonText: {
      height: "19px",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "19px",
      textTransform: "none",
      color: "#3A4F59 !important",
    },

    // Desktop
    h1: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "38px",
      lineHeight: "45px",
      color: "#000304",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "28px",
      color: "#000304",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "21px",
    },

    // Mobile
    h4: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "45px",
      color: "#000304",
    },
    h5: {
      fontWeight: "500",
      fontStyle: "normal",
      fontSize: "21px",
      lineHeight: "28px",
      color: "#001823",
    },
  },

  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
        },
        // variants
        outlined: {
          width: "225px",
          height: "35px",
          display: "flex",
          whiteSpace: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px 32px",
          background: "#FFFFFF",
          border: "2px solid #DC3727",
          boxSizing: "border-box",
          borderRadius: "40px",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "16px",
          lineHeight: "19px",
          textTransform: "none",
          "&:focus": {
            outline: "auto",
            outlineColor: "#000000",
          },
        },
        outlinedMobile: {
          width: "271px",
          height: "35px",
          display: "flex",
          whiteSpace: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px 32px",
          background: "#FFFFFF",
          border: "2px solid #DC3727",
          boxSizing: "border-box",
          borderRadius: "40px",
          color: "#DC3727",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "16px",
          lineHeight: "19px",
          textTransform: "none",
          "&:focus": {
            outline: "auto",
            outlineColor: "#000000",
          },
        },
        muiButtonContained: {
          background: "#DC3727",
          "&:focus": {
            outline: "auto",
            outlineColor: "#000000",
          },
          "&:hover": {
            outline: "auto",
            background: "#DC3727",
            outlineColor: "#000000",
          },
        },
        muiButtonDisabled: {
          background: "#E2E6E7",
        },
      },
    },
  },
});

export default facultyPortalTheme;
