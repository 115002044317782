import { Card, CardContent, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import CardLink from "../../../components/CardLink";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useStyles from "./MyCoursesCard.styles";
import { romanTest, capitalizeFoundParameter } from "../../../utils/library";
import { connect } from "react-redux";
import { getSolicitations } from "../../../redux/solicitations/solicitationsActions";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { storeErrorMessages } from "../../../redux/displayApiErrors/displayApiErrorsActions";

const MyCoursesCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const toggleAccommodations =
    process.env.REACT_APP_TOGGLE_ACCOMMODATIONS === "true";

  let cardKeyCount = 0;

  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    if (props.demographics.issuerId) {
      props.getSolicitations(props.demographics.issuerId);
      // props.getSolicitations("a12312312358938"); // fake IRN for testing purposes
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.solicitations.status !== undefined &&
      props.solicitations.status !== 200
    ) {
      props.storeErrorMessages(
        "Solicitation information not found.",
        "solicitation"
      );
    } else {
      setOpenSuccess(false);
    }
  }, [props.solicitations.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "solicitation"
    ) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]);

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.personId) {
      props.getSolicitations(props.demographics.issuerId);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return props.solicitations.length >= 1 ? (
    <>
      <Box
        className={
          matches ? classes.mainContainer : classes.mainContainerMobile
        }
      >
        <Box
          className={matches ? classes.innerContainer : classes.innerContainer}
        >
          <Typography variant="subtitle1" color="secondary" component="h2">
            {`My Courses`}
          </Typography>
          <Box sx={{ marginTop: "12px" }}>
            {props.solicitations.length === 1 ? (
              <Typography variant="body1">{`You have ${props.solicitations.length} new solicitation to review:`}</Typography>
            ) : (
              <Typography variant="body1">{`You have ${props.solicitations.length} new solicitations to review:`}</Typography>
            )}
          </Box>
          <Box>
            <List>
              {props.solicitations.length <= 2
                ? props.solicitations.map((solicitations) => (
                    <ListItem disablePadding key={cardKeyCount++}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "21px",
                          fontStyle: "normal",
                          marginBottom: "12px",
                        }}
                      >
                        {`${solicitations.courseId}: ${capitalizeFoundParameter(
                          romanTest(solicitations.courseTitle),
                          ["ai"]
                        )}`}
                      </Typography>
                    </ListItem>
                  ))
                : props.solicitations.slice(0, 2).map((solicitations) => (
                    <ListItem disablePadding key={cardKeyCount++}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "21px",
                          fontStyle: "normal",
                          marginBottom: "12px",
                        }}
                      >
                        {`${solicitations.courseId}: ${capitalizeFoundParameter(
                          romanTest(solicitations.courseTitle),
                          ["ai"]
                        )}`}
                      </Typography>
                    </ListItem>
                  ))}
            </List>
          </Box>
          <br />
          <Box className={classes.linkBox}>
            <Box className={matches ? classes.desktopLink : classes.mobileLink}>
              <CardLink
                content={"View solicitations"}
                link={"/solicitations"}
              />
            </Box>
            {toggleAccommodations && (
              <>
                <Box
                  className={matches ? classes.desktopLink : classes.mobileLink}
                >
                  <CardLink
                    content={"Accomodations dashboard"}
                    link={"/manageAccommodations"}
                  />
                </Box>
              </>
            )}
            <Box className={matches ? classes.desktopLink : classes.mobileLink}>
              <CardLink
                content={"Edit preferences"}
                link={"/coursepreferences"}
              />
            </Box>
            <Box className={matches ? classes.desktopLink : classes.mobileLink}>
              <CardLink content={"View course history"} link={"/mycourses"} />
            </Box>
          </Box>
        </Box>
      </Box>
      {openSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar("error", "Solicitation information not found.")
          : "")}
    </>
  ) : (
    <>
      <Box
        className={
          matches ? classes.mainContainer : classes.mainContainerMobile
        }
      >
        <Box
          className={matches ? classes.innerContainer : classes.innerContainer}
        >
          <Typography variant="subtitle1" color="secondary" component="h2">
            {`My Courses`}
          </Typography>
          <Box sx={{ marginTop: "12px" }}>
            <Typography variant="body1">{`You have no new solicitations at this time.`}</Typography>
          </Box>
          <br />
          <Box className={classes.linkBox}>
            <Box className={matches ? classes.desktopLink : classes.mobileLink}>
              <CardLink
                content={"View solicitations"}
                link={"/solicitations"}
              />
            </Box>
            {toggleAccommodations && (
              <>
                <Box
                  className={matches ? classes.desktopLink : classes.mobileLink}
                >
                  <CardLink
                    content={"Accomodations dashboard"}
                    link={"/manageAccommodations"}
                  />
                </Box>
              </>
            )}
            <Box className={matches ? classes.desktopLink : classes.mobileLink}>
              <CardLink
                content={"Edit preferences"}
                link={"/coursepreferences"}
              />
            </Box>
            <Box className={matches ? classes.desktopLink : classes.mobileLink}>
              <CardLink content={"View course history"} link={"/mycourses"} />
            </Box>
          </Box>
        </Box>
      </Box>
      {openSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar("error", "Solicitation information not found.")
          : "")}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    solicitations: state.solicitations,
    demographics: state.demographics,
    errorMessages: state.errorMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSolicitations: (irn) => dispatch(getSolicitations(irn)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCoursesCard);
