import { React, useState, useEffect, useRef } from "react";
import styles from "../styles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../facultyPortalTheme";
import {
  useMediaQuery,
  Box,
  Grid,
  Link,
  Typography,
  Paper,
  MenuItem,
  Button,
  TextField,
  Snackbar,
  TableBody,
  TableCell,
  TableRow,
  Modal,
} from "@material-ui/core";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import BlackArrow from "../../../images/BlackArrow.svg";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CourseTableCard from "./CourseTableCard";
import ActionNeeded from "./ActionNeeded";
import { getStudentAccommodations } from "../../../redux/studentAccommodations/studentAccommodationsActions";
import { getFacultyCurrentCourses } from "../../../services/faculty/v1/faculty";

const ManageAccommodations = (props) => {
  document.title = "Faculty Portal Manage Accommodations Page";
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();

  const [currentCourses, setCurrentCourses] = useState([]);
  const [students, setStudents] = useState([]);
  const [open, setOpen] = useState(false);

  let currentCoursesData = [];
  let currentCoursesRetStatus = 0;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
  };

  const getCurrentCourses = async (irn) => {
    await getFacultyCurrentCourses(irn)
      .then((response) => {
        currentCoursesData = response.data;
        currentCoursesRetStatus = response.status;
        setCurrentCourses(currentCoursesData);
        updateAccommodations(currentCoursesData, currentCoursesRetStatus);
      })
      .catch((error) => {
        currentCoursesData = error.request;
        currentCoursesRetStatus = error.status;
      });
  };

  const updateAccommodations = (currentCourses, retStatus) => {
    if (retStatus === 200 && currentCourses.length > 0) {
      for (let i = 0; i < currentCourses.length; i++) {
        let filterString = `courseOfferingId eq '${currentCourses[i].courseOfferingId}' and facultyIRN eq '${props.facultyDemographics.issuerId}'`;
        props.getStudentAccommodations(filterString);
      }
    }
  };

  useEffect(() => {
    if (
      props.facultyDemographics.issuerId !== null ||
      props.facultyDemographics.issuerId !== undefined
    ) {
      getCurrentCourses(props.facultyDemographics.issuerId);
    }
  }, [props.facultyDemographics.issuerId]);

  useEffect(() => {
    if (props.studentAccommodations.length === currentCourses.length) {
      for (let i = 0; i < currentCourses.length; i++) {
        let courseObject = {};
        courseObject.courseName = `${currentCourses[i].courseId}: ${currentCourses[i].courseTitle}`;
        courseObject.groupId = `${currentCourses[i].groupId}`;
        courseObject.startDate = `${currentCourses[i].startDate}`;
        courseObject.endDate = `${currentCourses[i].endDate}`;
        courseObject.offeringType = `${currentCourses[i].offeringType}`;
        let tempStudentList = [];
        const studentIrnList = currentCourses[i].accommodatedStudentIrnList !== undefined ? 
          currentCourses[i].accommodatedStudentIrnList : [];
        for (let j = 0; j < studentIrnList.length; j++) {
          let hasIrn = false;
          for (let k = 0; k < props.studentAccommodations[i].length; k++) {
            if (
              studentIrnList[j] === props.studentAccommodations[i][k].studentIRN
            ) {
              const student = {
                irn: studentIrnList[j],
                type: props.studentAccommodations[i][k].disabilityType,
                accomodations: props.studentAccommodations[i][k].accommodationType,
                notes: props.studentAccommodations[i][k].notes,
                newAccommodation: false,
              };
              tempStudentList.push(student);
              hasIrn = true;
            }
          }
          if (!hasIrn) {
            const student = {
              irn: studentIrnList[j],
              type: "",
              accomodations: "",
              notes: "",
              newAccommodation: true,
            };
            tempStudentList.push(student);
          }
        }
        courseObject.studentList = tempStudentList;
        setStudents([...students, courseObject]);
      }
    }
  }, [props.studentAccommodations, currentCourses]);



  // const tempStudents = [
  //   {
  //     irn: "9061686453",
  //     type: "all types",
  //     accomodations: "accom links",
  //   },
  //   {
  //     irn: "9067855082",
  //     type: "all types2",
  //     accomodations: "Everything2",
  //     newAccomodation: true,
  //   },
  //   {
  //     irn: "9997774187",
  //     type: "all types",
  //     accomodations: "Everything",
  //   },
  //   {
  //     irn: "9067040970",
  //     type: "all types2",
  //     accomodations: "Everything2",
  //   },
  //   {
  //     irn: "9067861562",
  //     type: "all types",
  //     accomodations: "Everything",
  //   },
  //   {
  //     irn: "9067577883",
  //     type: "all types2",
  //     accomodations: "Everything2",
  //   },
  //   {
  //     irn: "7",
  //     type: "all types",
  //     accomodations: "Everything",
  //   },
  //   {
  //     irn: "9997778992",
  //     type: "all types2",
  //     accomodations: "Everything2",
  //   },
  //   {
  //     irn: "9",
  //     type: "all types",
  //     accomodations: "Everything",
  //   },
  //   {
  //     irn: "9067002032",
  //     type: "all types2",
  //     accomodations: "Everything2",
  //   },
  //   {
  //     irn: "11",
  //     type: "all types",
  //     accomodations: "Everything",
  //   },
  //   {
  //     irn: "12",
  //     type: "all types2",
  //     accomodations: "Everything2",
  //   },
  //   {
  //     irn: "9061686453",
  //     type: "all types",
  //     accomodations: "Everything",
  //   },
  //   {
  //     irn: "14",
  //     type: "all types2",
  //     accomodations: "Everything2",
  //   },
  // ];


  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Box
        className={
          matches
            ? classes.pageContainer
            : tabletMatches
            ? classes.pageContainerTablet
            : classes.pageContainerMobile
        }
      >
        <Grid
          item
          className={
            matches
              ? classes.linkContainer
              : tabletMatches
              ? classes.linkContainerTablet
              : classes.linkContainerMobile
          }
        >
          <Link
            id="backToDashboardLink"
            data-testid="back_to_dashboard_link"
            underline="none"
            className={classes.backToDashboardLink}
            href="/"
          >
            <img className={classes.arrowImage} src={BlackArrow} alt="" />
            <Typography>{`Course Dashboard`}</Typography>
          </Link>
        </Grid>
        <div
          style={{
            maxWidth: "1232px", // Maximum width for large screens
            width: "86%", // Leaves a 7% margin on each side (100% - 2 * 7%)
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "8%",
              marginTop: "1%",
            }}
          >
            <Typography
              id="pageHeader"
              data-testid="page_header"
              component="h1"
              className={
                matches
                  ? classes.pageHeader
                  : tabletMatches
                  ? classes.pageHeaderTablet
                  : classes.pageHeaderMobile
              }
            >
              {"Manage accommodations"}
            </Typography>
          </Box>
          <Box className={classes.mainContentContainer}>
            <Typography
              component="p"
              className={classes.newFont}
              style={{ marginBottom: "24px" }}
            >
              {`University of Phoenix is required to prohibit discrimination and to provide reasonable accommodations and modifications to qualified students in all University programs and activities under the law.`}
            </Typography>
            <Typography component="p" className={classes.newFont}>
              {`As faculty of University of Phoenix, you are responsible for implementing the approved accommodations for students in class. Accommodations are determined after a deliberative and interactive process between the student and University staff. Accommodations must be provided as indicated, following all guidelines and directives provided in the accommodation notification.`}
            </Typography>
          </Box>
          {students.length > 0 ? (
            students.map((student) => (
              <CourseTableCard
                courseName={student.courseName}
                groupId={student.groupId}
                startDate={student.startDate}
                endDate={student.endDate}
                offeringType={student.offeringType}
                students={student.studentList}
                setOpen={setOpen}
              />
            ))
          ) : (
            // <>
            //   <CourseTableCard students={tempStudents} setOpen={setOpen} />
            //   <CourseTableCard students={tempStudents} setOpen={setOpen} />
            // </>
            <>
              <TableCell colSpan={6} className={classes.emptyStateContainer}>
                <Typography className={classes.noStudentsText}>
                  You currently do not have any accommodated students.
                </Typography>
              </TableCell>
            </>
          )}
        </div>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown // This disables closing the modal with the Escape key
        className={classes.actionModal}
      >
        <div className={classes.modalContent}>
          <ActionNeeded setOpen={setOpen} />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    facultyDemographics: state.demographics,
    facultyContacts: state.facultyContacts,
    studentAccommodations: state.studentAccommodations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentAccommodations: (filter) =>
      dispatch(getStudentAccommodations(filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAccommodations);
