import styles from "./Welcome.styles";

import { Typography, useMediaQuery } from "@material-ui/core";
import Sun from "../../../images/Sun.svg";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

const Welcome = (props) => {
  // Allow for MUI styling
  const classes = styles();
  // Utilize useMediaQuery to create UI view for Mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentUser, setCurrentUser] = useState("");
  const [greeting, setGreeting] = useState("");

  // create new Date object, initialize Greeting statement, and set current user
  let time = new Date().toLocaleTimeString("en-US");

  useEffect(() => {
    if (props.demographics.firstName !== undefined) {
      setCurrentUser(
        `${props.demographics.firstName[0]}${props.demographics.firstName
          .substring(1)
          .toLowerCase()}`
      );
    }
  }, [props.demographics]);

  // determine whether it is morning, afternoon or evening time
  useEffect(() => {
    if (props.demographics.firstName !== undefined) {
      if (new RegExp("AM").test(time)) {
        setGreeting(`Good Morning, ${currentUser}`);
      }
      if (
        (parseInt(time.substr(0, 1)) < 5 || time.substr(0, 2) === "12") &&
        new RegExp("PM").test(time)
      ) {
        setGreeting(`Good Afternoon, ${currentUser}`);
      }
      if (
        parseInt(time.substr(0, 1)) >= 5 &&
        time.substr(0, 2) !== "12" &&
        new RegExp("PM").test(time)
      ) {
        setGreeting(`Good Evening, ${currentUser}`);
      }
    } else {
      if (new RegExp("AM").test(time)) {
        setGreeting("Good Morning");
      }
      if (
        (parseInt(time.substr(0, 1)) < 5 || time.substr(0, 2) === "12") &&
        new RegExp("PM").test(time)
      ) {
        setGreeting("Good Afternoon");
      }
      if (
        parseInt(time.substr(0, 1)) >= 5 &&
        time.substr(0, 2) !== "12" &&
        new RegExp("PM").test(time)
      ) {
        setGreeting("Good Evening");
      }
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return matches ? (
    <div className={classes.welcomeContainerMobile}>
      <Typography
        className={classes.portalTitle}
      >{`FACULTY PORTAL`}</Typography>
      <div className={classes.textContainer}>
        <Typography variant="h4" component="h1" data-testid="welcomeStatement">
          {greeting}
        </Typography>
      </div>
      {/* <div className={classes.sunDivMobile}>
        <img
          src={Sun}
          alt=""
          data-testid="sunIcon"
          className={classes.sunMobile}
        />
      </div> */}
    </div>
  ) : (
    <div className={classes.welcomeContainer}>
      <Typography
        className={classes.portalTitle}
      >{`FACULTY PORTAL`}</Typography>

      <div className={classes.rowContainer}>
        <Typography variant="h1" component="h1" data-testid="welcomeStatement">
          {`${greeting}`}
        </Typography>
        {/* <img src={Sun} alt="" data-testid="sunIcon" className={classes.sun} /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(Welcome);
