import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import CardLink from "./CardLink";

const useStyle = makeStyles(() => ({
  // Desktop View
  desktopCard: {
    width: "257px",
    marginBottom: "20px",
  },
  desktopCardContent: {
    margin: "12px 12px 0 12px",
  },
  desktopLink: {
    display: "flex",
   // marginTop: "29px",
  },

  // Mobile View
  mobileCard: {
    minWidth: "327px",
    //height: "178px",
    marginBottom: "24px",
  },
  mobileCardContent: {
    margin: "4px 5px 20px 5px",
    paddingBottom: "0 !important",
  },
  mobileLink: {
    display: "flex",
    //justifyContent: "flex-end",
    //marginTop: "18px",
  },

  // Used for both Desktop and Mobile View
  hideDiv: {
    display: "none",
  },
}));

const SchedulerCard = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
    <Card className={matches ? classes.desktopCard : classes.mobileCard}>
      <CardContent
        className={
          matches ? classes.desktopCardContent : classes.mobileCardContent
        }
      >
        <Typography
          component="h2"
          variant="subtitle1"
          color="secondary"
        >{`My Scheduler`}</Typography>
        <>
        {props.scheduler.length === undefined || props.scheduler.length === 0 ? 
        <Typography
            sx={{ color: "#000304", fontSize:"21px",fontStyle:"Arial",marginTop: "20px"}}
            variant="subtitle2"
            component= "h3"
         >
        No Scheduler information currently
        </Typography> 
    :''}

        {props.scheduler.length > 0 ? 
        props.scheduler.map((scheduler) =>
         
        <Box>
        <Box
          sx={
            matches
              ? { marginTop: "16px", marginBottom: "2px" }
              : { marginTop: "14px", marginBottom: "2px" }

          }
        >
                  
          <Typography
            sx={{ color: "#000304", fontSize:"20px", fontWeight:700 }}
            variant="subtitle2"
            component="h3"
          >
             
            {scheduler.name === undefined
              ? "No Scheduler information currently"
              : scheduler.name === null
              ? "No Scheduler information currently"
              : 
             
              `${scheduler.name}`}
          </Typography>
        </Box>
        <Box>
        <Typography variant="body2" sx={{ color: "#5E7079", fontWeight:700,fontSize:"16px", fontStyle:"italic" }}>
            {scheduler.dept === undefined
              ? ""
              : scheduler.dept === null
              ? ""
              : `${scheduler.dept}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ color: "#000304" }}>
            {scheduler.email === undefined
              ? ""
              : scheduler.email === null
              ? ""
              : `${scheduler.email}`}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textDecoration: "underline",
              color: "#000304",
            }}
          >
            {scheduler.phone === undefined
              ? ""
              : scheduler.phone === null
              ? ""
              : `${scheduler.phone}`}
          </Typography>
        </Box> 
        <Box className={matches ? classes.desktopLink : classes.mobileLink}>
          <div
            className={
              scheduler.name === undefined
                ? classes.hideDiv
                : scheduler.name === null
                ? classes.hideDiv
                : ""
            }
          >
            <CardLink
              schedulerEmail={true}
              content={
                scheduler.name !== undefined
                  ? `Email ${scheduler.name.split(" ")[0]}`
                  : null
              }
              link={`mailto:${scheduler.email}`}
            />
          </div>
        </Box>
        </Box>
        ): ''}
        </>
    
      </CardContent>
    </Card>
      </>
  );
};
const mapStateToProps = (state) => {
  return {
    scheduler: state.scheduler,
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(SchedulerCard);
