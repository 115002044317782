import CourseCard from "./CourseCard";
import React, { useState, useEffect } from "react";
import FacultyPortalStyles from "../../../../styles.js";
import styles from "./CourseCardDisplay.styles";
import { connect } from "react-redux";
import NoCurrentCourses from "./NoCurrentCourses.jsx";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper";
import {
  getFacultyCurrentCourses,
  getFacultyWorkshops,
  getAccommodations,
} from "../../../../services/faculty/v1/faculty";
import facultyPortalTheme from "../../../../facultyPortalTheme.js";
import { getFeocsInvite } from "../../../../redux/feocsInviteLink/feocsInviteLinkActions";
import { storeErrorMessages } from "../../../../redux/displayApiErrors/displayApiErrorsActions";
import { getStudentAccommodations } from "../../../../redux/studentAccommodations/studentAccommodationsActions"; // make non redux version
import { getSectionsByIrn } from "../../../../redux/workshops/workshopActions.js";

const CourseCardDisplay = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const facultyClasses = FacultyPortalStyles();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [ccOpenSuccess, setCcOpenSuccess] = useState(false);
  const [wsSectionsOpenSuccess, setWsSectionsOpenSuccess] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [currentCourseData, setCurrentCourseData] = useState({});
  // const [workshopData, setWorkshopData] = useState([]);
  // const [wsData, setWsData] = useState([]);
  const [ccData, setCcData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [workshopsToDisplay, setWorkshopsToDisplay] = useState([]);
  const [allAccommodations, setAllAccommodations] = useState([]);

  const toggleDatabaseDate = new Date(process.env.REACT_APP_TOGGLE_DATE);
  const currentDate = new Date();
  const toggleAccommodations = process.env.REACT_APP_TOGGLE_ACCOMMODATIONS === "true";

  let courseCardKeyCount = 0;

  useEffect(() => {
    if (currentDate >= toggleDatabaseDate || props.wsUser) {
      handleGetSectionsForIrn(props.demographics.issuerId);
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentCourseData && currentCourseData.length > 0) {
      currentCourseData.forEach((currentCourse) => {
        const newAccommodation = getCourseAccommodations(
          currentCourse.courseOfferingId
        );

        // Check if the newAccommodation is not undefined and doesn't already exist in the state
        if (
          newAccommodation &&
          !allAccommodations.some(
            (accommodation) =>
              accommodation.courseOfferingId === currentCourse.courseOfferingId
          )
        ) {
          setAllAccommodations((prevAccommodations) => [
            ...prevAccommodations,
            newAccommodation,
          ]);
        }
      });
    }
  }, [currentCourseData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetSectionsForIrn = (irn) => {
    if (irn) {
      props.getSectionsByIrn(irn);
    }
  };

  function convertToLocalWithoutTimestamp(utcDate) {
    let utc = new Date(utcDate);
    let localDate = new Date(
      utc.toLocaleString(undefined, { timeZone: "UTC" })
    );
    localDate.setHours(0, 0, 0, 0);
    return localDate;
  }

  function addDays(date, daysToAdd) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }

  // new Set workshop sections data
  const setWorkshopSectionsData = (sectionList) => {
    if (sectionList.length > 0) {
      const filtered = sectionList.filter((section) => {
        return (
          section.status === "Scheduled" &&
          convertToLocalWithoutTimestamp(
            addDays(
              new Date(section.startDate),
              -process.env.REACT_APP_WS_REG_DEADLINE_DAYS
            )
          ) <= convertToLocalWithoutTimestamp(new Date()) &&
          convertToLocalWithoutTimestamp(
            addDays(new Date(section.endDate), 7)
          ) >= convertToLocalWithoutTimestamp(new Date())
        );
      });
      setWorkshopsToDisplay(filtered);
    }
  };

  useEffect(() => {
    if (props.demographics.issuerId) {
      fetchCurrentCoursesData();
      props.getFeocsInvite(props.demographics.issuerId);

      if (currentDate < toggleDatabaseDate && !props.wsUser) {
        fetchWorkshopData();
      } else {
        setWorkshopSectionsData(props.sectionsForIrn);
      }
    }

    // return () => {
    //   setWsData([]);
    // };
  }, [props.demographics.issuerId, props.sectionsForIrn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const bothCourseList = [];
    if (ccData.length > 0) {
      ccData.map((course) => bothCourseList.push(course));
    }
    if (workshopsToDisplay.length > 0) {
      if (currentDate < toggleDatabaseDate && !props.wsUser) {
        workshopsToDisplay.map((course) => bothCourseList.push(course));
      } else {
        workshopsToDisplay.map((course) =>
          bothCourseList.push({
            courseId: course.courseCode,
            courseOfferingId: course.id,
            courseTitle: course.courseTitle,
            description: course.templateDesc,
            endDate: `${course.endDate} 00:00:00`,
            groupId: course.groupId,
            offeringType: "WS",
            rosterId: course.rosterId,
            schedulingRole: course.role,
            status: course.status,
            startDate: `${course.startDate} 00:00:00`,
            capacity: course.capacity,
            facultyName: `${props.demographics.firstName} ${props.demographics.lastName}`,
          })
        );
      }
    }
    setCourseData(bothCourseList);
  }, [workshopsToDisplay, ccData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Below Code deals with retreiving current courses info and the old way of retrieving workshops info
  // -------------------------------------------------------------------------------------------------------------
  let retStatus = 0;
  let data;

  const getCurrentCourses = async (irn) => {
    await getFacultyCurrentCourses(irn)
      .then((response) => {
        retStatus = response.status;
        data = response.data;
      })
      .catch((error) => {
        setCurrentCourseData(error.request);
        retStatus = error.status;
      });
    return { retStatus: retStatus, data: data };
  };

  const fetchCurrentCoursesData = async (event) => {
    // sends the get request
    await getCurrentCourses(props.demographics.issuerId)
      //await getCurrentCourses("fakeIRN")
      .then((response) => {
        if (response.retStatus === 200) {
          setCurrentCourseData(response.data);
          setCcOpenSuccess(false);
          setCcData(response.data);
        } else {
          setCcOpenSuccess(true);
          // setErrorMessage("Current course information not found.");
        }
      });
  };

  // old getWorkshops
  const getWorkshops = async (profileId) => {
    await getFacultyWorkshops(profileId)
      .then((response) => {
        retStatus = response.status;
        // console.log(response.status);
        data = response.data;
      })
      .catch((error) => {
        // setWorkshopData(error.request);
        retStatus = error.status;
      });
    return { retStatus: retStatus, data: data };
  };

  // old fetch workshop data
  const fetchWorkshopData = async (event) => {
    // console.log(props.demographics[`externalSystemIds`].PROFILE_ID[0]);
    // sends the get request
    await getWorkshops(props.demographics[`externalSystemIds`].PROFILE_ID[0])
      // await getWorkshops("fakeIRN")
      .then((response) => {
        if (response.retStatus === 200) {
          // setWorkshopData(response.data);
          setWsSectionsOpenSuccess(false);
          //setWorkshopSectionsData(response.data);
          setWorkshopsToDisplay(response.data);
        } else {
          setWsSectionsOpenSuccess(true);
          // setErrorMessage("Workshop information not found.");
        }
      });
  };

  const fetchAccommodations = async (filterString) => {
    let retStatus;
    let data = [];

    try {
      // Sends the get request
      const response = await getAccommodations(filterString);
      retStatus = response.status;
      data = response.data;

      // Update state with unique accommodations based on the `id` field
      setAllAccommodations((prevAccommodations) => {
        // Combine the existing and new accommodations
        const combinedAccommodations = [...prevAccommodations, ...data];

        // Filter to remove duplicates based on `id`
        const uniqueAccommodations = combinedAccommodations.filter(
          (accommodation, index, self) =>
            index === self.findIndex((a) => a.id === accommodation.id)
        );

        return uniqueAccommodations; // Set unique accommodations to state
      });
    } catch (error) {
      retStatus = error.response?.status || 500; // Handles error if response or status is undefined
    }

    return { retStatus, data };
  };

  const getCourseAccommodations = (offeringId) => {
    if (props.demographics.issuerId) {
      let filterString = `courseOfferingId eq '${offeringId}' and facultyIRN eq '${props.demographics.issuerId}'`;
      fetchAccommodations(filterString);
    }
  };

  // -------------------------------------------------------------------------------------------------------------

  // Below Code deals with Snackbar
  // -------------------------------------------------------------------------------------------------------------
  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={
          openSuccess
            ? true
            : ccOpenSuccess
            ? true
            : wsSectionsOpenSuccess
            ? true
            : false
        }
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.issuerId) {
      if (ccOpenSuccess) {
        fetchCurrentCoursesData();
      }
      if (wsSectionsOpenSuccess) {
        handleGetSectionsForIrn(props.demographics.issuerId);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (
      currentCourseData.status !== undefined &&
      currentCourseData.status !== 200
    ) {
      props.storeErrorMessages(
        "Current course information not found.",
        "current courses"
      );
    } else {
      setCcOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [currentCourseData.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.sectionsForIrn.status !== undefined &&
      props.sectionsForIrn.status !== 200
    ) {
      props.storeErrorMessages(
        "Workshop Sections information not found.",
        "sectionsForIrn"
      );
    } else {
      setWsSectionsOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [props.sectionsForIrn.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "current courses"
    ) {
      setCcOpenSuccess(true);
      setOpenSuccess(true);
    }
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "sectionsForIrn"
    ) {
      setWsSectionsOpenSuccess(true);
      setOpenSuccess(true);
    }
  }, [props.errorMessages]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------------------------------------------------------------------

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      {ccOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
      {wsSectionsOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
      {courseData.length >= 1 ? (
        courseData
          .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
          .map((currentCourse) => {
            // Filter all accommodations that match the current courseOfferingId
            const courseAccommodations = allAccommodations.filter(
              (accommodation) =>
                accommodation.courseOfferingId ===
                currentCourse.courseOfferingId
            );

            return (
              <div
                className={currentCourse.courseId === null ? classes.hide : ""}
                key={courseCardKeyCount++}
              >
                <CourseCard
                  matches={matches}
                  type="courseCardDisplay"
                  currentCourse={currentCourse}
                  wsUser={props.wsUser}
                  courseAccommodations={courseAccommodations} // Pass entire entries matching courseOfferingId
                />
              </div>
            );
          })
      ) : (
        <NoCurrentCourses />
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    feocsInviteLink: state.feocsInviteLink,
    sectionsForIrn: state.sectionsForIrn,
    demographics: state.demographics,
    errorMessages: state.errorMessages,
    studentAccommodations: state.studentAccommodations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeocsInvite: (irn) => dispatch(getFeocsInvite(irn)),
    getSectionsByIrn: (irn) => dispatch(getSectionsByIrn(irn)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
    getStudentAccommodations: (filter) =>
      dispatch(getStudentAccommodations(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseCardDisplay);