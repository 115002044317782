import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  desktopContent: {
    // width: "410px",
    // height: "88px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  desktopRow: {
    display: "flex",
    alignItems: "center",
    margin: "4px 0px 0px 0px",
  },
  infoIcon: {
    // height: "27px",
    // width: "24px",
    marginTop: "3px",
  },

  // Mobile View
  mobileContent: {
    // height: "100px",
    margin: "12px 0 5px 0",
  },
  mobileRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "8px",
  },
  hideDiv: {
    display: "none",
  },
  infoIconMobile: {
    // height: "27px",
    // width: "24px",
    marginTop: "4px",
  },
  iconMobile: {
    // marginTop: "2px",
  },
});
