import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  Link,
  Button,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import SaveIcon from '@mui/icons-material/Save';
import styles from "../styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { connect, useSelector } from "react-redux";
import { getPersonInfoWithName } from "../../../redux/demographics/demographicsActions";
import { selectPersonByIrnAndType } from "../../../redux/demographics/personsWithNameSelector";
import { updateStudentAccommodation } from "../../../services/faculty/v1/faculty";

function CourseTableRow(props) {
  const useStyles = styles;
  const classes = useStyles();
  const state = useSelector((state) => state);

  const [originalNote, setOriginalNote] = useState(props.student.notes);
  const [noteValue, setNoteValue] = useState(props.student.notes !== "" ? props.student.notes : "");
  const [noteHasChanged, setNoteHasChanged] = useState(false);

  const [studentFullName, setStudentFullName] = useState("");
  const [studentPersonId, setStudentPersonId] = useState("");

  const [focused, setFocused] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  const [phoenixTimestamp, setPhoenixTimeStamp] = useState(getPhoenixTimestamp());                                                                                        
  const [gradeValue, setGradeValue] = useState(true); 
  const [testAccommodationObject, setTestAccommodationObject] = useState({
    id: 2,
    facultyIRN: 41066,
    facultyPersonId: "123",
    studentIRN: 9067862659,
    studentPersonId: "123",
    courseOfferingId: 33790707,
    accommodationType: "help",
    notes: "timestamp test",
    finalGradePosted: true,
    dateAcknowledged: "2024-09-27 13:27:00",
    disabilityType: "2323",
    dateModified: phoenixTimestamp, // will need to update with actual timestamp
    modifiedBy: "123", // will need to update by personId (logged in faculty)
  });

  function getPhoenixTimestamp() {
    const options = {
      timeZone: "America/Phoenix",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the current date in Phoenix time
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(new Date());

    // Extract date and time components
    const year = parts.find((part) => part.type === "year").value;
    const month = parts.find((part) => part.type === "month").value;
    const day = parts.find((part) => part.type === "day").value;
    const hour = parts.find((part) => part.type === "hour").value;
    const minute = parts.find((part) => part.type === "minute").value;
    const second = parts.find((part) => part.type === "second").value;

    // Construct the timestamp in "YYYY-MM-DD HH:MM:SS" format
    const phoenixTimestamp = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    return phoenixTimestamp;
  }

  const studentPersonType = "student";

  const handleSaveNotes = () => {
    // endpoint call to save note goes here
    const timestamp = getPhoenixTimestamp();
  
    setTestAccommodationObject((prevState) => {
      const updatedObject = {
        ...prevState,
        dateModified: timestamp,
        notes: noteValue,
      };
      
      console.log(updatedObject);
      handleUpdateStudentAccommodation(updatedObject);
      
      return updatedObject;
    })
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };


    const handleGradeChange = (event) => {
      console.log(event.target.value);
      const value = event.target.value === "Yes" ? true : false;
      setGradeValue(value);
    
      // Use a callback to ensure the state is fully updated before calling the API
      setTestAccommodationObject((prevState) => {
        const updatedObject = {
          ...prevState,
          finalGradePosted: value,
        };
        
        console.log(updatedObject);
        handleUpdateStudentAccommodation(updatedObject);
        
        return updatedObject;
      });
    };

    const handleNotesChange = (event) => {
      if (originalNote !== event.target.value) {
        setNoteHasChanged(true);
      } else {
        setNoteHasChanged(false);
      }

      setNoteValue(event.target.value);
    };

  const handleUpdateStudentAccommodation = async (accommodation) => {
    await updateStudentAccommodation(accommodation)
      .then((res) => {
        setSnackbarType("success");
        setSnackbarMessage("Updated student accommodation");
        setNoteHasChanged(false);
        setOpenSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarType("error");
        setSnackbarMessage("Failed to update student accommodation");
        setOpenSuccess(true);
      });
  };


  useEffect(() => {
    if (props.student && props.student.irn && !studentFullName) {
      props.getPersonInfoWithName(props.student.irn, studentPersonType);
    }
  }, []);

  useEffect(() => {
    if (props.student && props.student.irn) {
      const studentPerson = selectPersonByIrnAndType(
        state,
        props.student.irn,
        studentPersonType
      );
      if (studentPerson) {
        setStudentPersonId(studentPerson.id);
        setStudentFullName(
          `${studentPerson.firstName} ${studentPerson.lastName}`
        );
        return;
      }
    } else {
      setStudentPersonId(null);
      setStudentFullName("");
    }
  }, [props.student, state]);

  // Below code is to handle snackbars for successful or errored submissions
  // -------------------------------------------------------------------------------------------
  const renderSnackbar = () => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={snackbarType === "success" ? 10000 : 13000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
  const handleCloseSnackBar = (event, reason) => {
    setOpenSuccess(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  
  const handleRetryApiCall = () => {
    switch (snackbarMessage) {
      case "Failed to update student accommodation.":
        handleUpdateStudentAccommodation(testAccommodationObject);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TableRow
        key={props.student.irn}
        className={
          props.student.newAccommodation
            ? classes.newAccomContainer
            : classes.rowContainer
        }
      >
        <TableCell
          align="left"
          className={
            props.student.newAccommodation
              ? classes.newAccomStickyColumn
              : classes.stickyColumn
          }
        >
          <Typography id={`studentFullName${props.key}-${props.courseOfferingId}`} className={classes.boldText}>
            {studentFullName}
          </Typography>
          {
            /*remove studentPersonId and line break from display later*/ studentPersonId
          }{" "}
          <br />
          {props.student.irn}
        </TableCell>
        <TableCell align="left">{props.student.type}</TableCell>
        <TableCell align="left">
          <Button href="#" role="button" className={classes.linkText}>
            <Typography>{props.student.accomodations}</Typography>
          </Button>
        </TableCell>
        <TableCell>
          <TextField
            id="description notes" // Unique ID for accessibility
            name="course"
            onChange={handleNotesChange}
            // placeholder={
            //   focused && noteValue.length === 0 ? "Type something..." : ""
            // }
            value={noteValue}
            placeholder={focused && noteValue.length === 0 ? "" : ""}
            variant="outlined"
            color="secondary"
            //disabled={props.student.newAccommodation}
           // aria-disabled={props.student.newAccommodation}
            InputProps={{
              maxLength: 4000,
              endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      position="end" 
                      className={classes.iconButton}
                      onClick={handleSaveNotes}
                      tabIndex={0}
                      role="button"
                      aria-label="Save notes"
                      aria-describedby={`studentFullName${props.key}-${props.courseOfferingId}`}
                      disabled={!noteHasChanged}
                    >
                      <SaveIcon aria-describedby={'save notes button'} className={noteHasChanged? classes.saveIcon: classes.saveIconDisabled}/>
                    </IconButton>
                  </InputAdornment>)
            }}
            className={
            //   props.student.newAccommodation
            //     ? classes.textFieldGray
                // : 
                classes.textField
            }
            label={
              noteValue.length > 0 && !focused
                ? ""
                : focused
                ? noteValue.length === 0
                  ? "Optional field"
                  : "Optional field"
                : "Type something..."
            }
            InputLabelProps={{
              shrink: noteValue.length > 0 || focused, // Shrinks the label if there is a value or the field is focused
            }}
            onFocus={handleFocus}
            // onBlur={(e) => {
            //   handleBlur(e); // Call the existing blur handler
            //   handleNotesChange(noteValue);
            // }}
          />
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Select
            value={gradeValue ? "Yes" : "No"}
            onChange={handleGradeChange}
            displayEmpty
            variant="outlined"
            color="secondary"
            className={
              props.student.newAccommodation
                ? classes.selectGray
                : classes.select
            }
            disabled={props.student.newAccommodation}
          >
            <MenuItem
              value="Yes"
              aria-label="Yes"
              className={classes.menuItemFocused}
            >
              Yes
            </MenuItem>
            <MenuItem
              value="No"
              aria-label="No, negative"
              className={classes.menuItemFocused}
            >
              No
            </MenuItem>
          </Select>
        </TableCell>
        <TableCell align="center">
          {props.student.newAccommodation ? (
            <>
              <div>
                <WarningIcon
                  className={classes.warningIcon}
                  aria-label="warning icon"
                  aria-hidden="false"
                />
              </div>
              <Button
                href="#"
                className={classes.linkText}
                role="button"
                onClick={() => props.setOpen(true)}
              >
                <div>
                  <Typography>New student</Typography>
                  <Typography>accomodation</Typography>
                </div>
              </Button>
            </>
          ) : (
            <>
              <Button
                href="#"
                role="button"
                className={classes.linkText}
                aria-label="Review FNF link"
              >
                <Typography>Review FNF</Typography>
              </Button>
            </>
          )}
        </TableCell>
      </TableRow>
      {openSuccess && renderSnackbar()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    personsWithName: state.personsWithName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonInfoWithName: (irn, personType) =>
      dispatch(getPersonInfoWithName(irn, personType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseTableRow);
