import { React, useState, useEffect, useRef } from "react";
import styles from "../helpDeskSubmissionStyles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../facultyPortalTheme";
import {
    useMediaQuery,
    Box,
    Grid,
    Link,
    Typography,
    Paper,
    MenuItem,
    Button,
    TextField,
    Snackbar,
} from "@material-ui/core";
import ErrorIcon from "@mui/icons-material/Error";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import BlackArrow from "../../../images/BlackArrow.svg";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import {useLocation} from "react-router-dom";
import { connect } from "react-redux";
import { getCaseTypes, getIssueTypes } from "../../../redux/helpDeskSubmission/helpDeskSubmissionActions";

const HelpDeskSubmissionPage = (props) => {

document.title = "Faculty Portal Help Desk Submission Page";

  const classes = styles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));

  const location = useLocation();
  //const profile_id = new URLSearchParams(location.search).get('facultyProfileId');
  // manage state change for submission error message
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  // manage state changes for comments text area
  const [comments, setComments] = useState("");

  // manage state changes for input fields
  const [facultyIrnInput, setFacultyIrnInput] = useState("");
  const [facultyNameInput, setFacultyNameInput] = useState("");
  const [facultyEmailInput, setFacultyEmailInput] = useState("");
  const [caseTypeSelected, setCaseTypeSelected] = useState("");
  const [issueTypeSelected,setIssueTypeSelected] = useState("");
  const [caseTypeSelectExpand, setCaseTypeSelectExpand] = useState(false);
  const [issueTypeSelectExpand, setIssueTypeSelectExpand] = useState(false);
  
  const calculateDueDate = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 5 for Friday, 6 for Saturday
   
    let dueDate;
    if (currentDay === 5) { // Friday
      dueDate = new Date(currentDate);
      dueDate.setDate(currentDate.getDate() + 3); // Move to Monday
    } else {
      dueDate = new Date(currentDate);
      dueDate.setDate(currentDate.getDate() + 1); // Move to next day
    }
   
    return dueDate;
  }
   
  const dueDate = calculateDueDate();

  const facultyFields = [
    {
      id: "facultyName",
      datatestID: "faculty_name",
      ariaLabel: "faculty name",
      value: "facultyName",
      label: "Your Name",
      inputVal: facultyNameInput,
    },
    {
        id: "facultyIRN",
        datatestID: "faculty_IRN",
        ariaLabel: "faculty irn",
        value: "facultyIRN",
        label: "Your IRN",
        inputVal: facultyIrnInput,
      },
    {
      id: "facultyEmail",
      datatestID: "faculty_email",
      ariaLabel: "faculty email",
      value: "facultyEmail",
      label: "UOPX Email Address",
      inputVal: facultyEmailInput,
    }
  ];

  useEffect(() => {
    if(props.caseTypes.status !== undefined && props.caseTypes.status !== 200)
  {  
     setOpenSuccess(true)
     setSubmitErrorMessage(
      "Cannot retreive case types"
    )
  } else {
    setOpenSuccess(false)
  }
  }, [props.caseTypes]);

  useEffect(() => {
    if(props.issueTypes.status !== undefined && props.issueTypes.status !== 200)
  {  
    setOpenSuccess(true)
    setSubmitErrorMessage(
      "Cannot retreive issue types"
    )
  } else {
    setOpenSuccess(false)
  }
  }, [props.issueTypes]);


  useEffect(() => {
    props.getCaseTypes()
    }, []);

  useEffect(() => { 
    if(caseTypeSelected !== "") {
      props.getIssueTypes(caseTypeSelected)
      setCaseTypeErrorDisplay(false)
  }
 }, [caseTypeSelected]);

   useEffect(() => {
    if(comments !== ""){
    setCommentsErrorDisplay(false)
    }
    }, [comments]);

  // code to handle error messages occuring on submit.
  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (submitErrorMessage !== "") {
      setOpenSuccess(true);
    }
  }, [submitErrorMessage]);

  // Manage error display use states
  const [commentsErrorDisplay, setCommentsErrorDisplay] = useState();
  const [caseTypeErrorDisplay, setCaseTypeErrorDisplay] = useState();
  const [studentAndFacultyErrorDisplay, setStudentAndFacultyErrorDisplay] = useState();

  const testForNullSubmissions = (event) => {

    let tempCommentsError = false;
    let tempCaseTypeError = false;
    //faculty fields
    if (
        (facultyIrnInput !== "" && facultyIrnInput !== null && facultyIrnInput !== undefined) &&
        (facultyNameInput !== "" && facultyNameInput !== null && facultyNameInput !== undefined) &&
        (facultyEmailInput !== "" && facultyEmailInput !== null && facultyEmailInput !== undefined)
    ) {
        setStudentAndFacultyErrorDisplay(false);
    } else {
        event.preventDefault();
        //handleScrollToTop();
        setStudentAndFacultyErrorDisplay(true);
    }
    // check for case type
    if (caseTypeSelected !== "") {
      tempCaseTypeError = false;
      setCaseTypeErrorDisplay(false);
  } else {
      event.preventDefault();
      tempCaseTypeError = true;
        if (tempCaseTypeError) {
          const heading = document.getElementById("caseType");
          heading.setAttribute('tabIndex', '0');
          heading.focus();
        };
      setCaseTypeErrorDisplay(true);
      if(caseTypeSelected){
        tempCaseTypeError = false;
      }
  }
    // check for comments
    if (comments !== "") {
      tempCommentsError = false;  
      setCommentsErrorDisplay(false);
    } else {
        event.preventDefault();
        tempCommentsError = true;
        if (!tempCaseTypeError && tempCommentsError) {
          const heading = document.getElementById("label");
          heading.setAttribute('tabIndex', '-1');
          heading.focus();
        };
        setCommentsErrorDisplay(true);
    }
   };

  const handleCaseTypeChange = (event) => {
    setCaseTypeSelected(event.target.value
    );
  };
  
  const handleIssueTypeChange = (event) => {
    setIssueTypeSelected(event.target.value);
  };



  // code to handle submission
  const handleSubmit = (event) => {
    testForNullSubmissions(event);
  };

  useEffect(() => {
    if(
      props.facultyDemographics.firstName !== null && props.facultyDemographics.lastName !== null &&
      props.facultyDemographics.firstName !== undefined && props.facultyDemographics.lastName !== undefined) 
    {
        setFacultyNameInput(`${props.facultyDemographics.firstName} ${props.facultyDemographics.lastName}`)
        setFacultyIrnInput(`${props.facultyDemographics.issuerId}`)
    }
    if(props.facultyContacts.length !== 0) {
      for(let contact = 0; contact < props.facultyContacts.length; contact++) {
        if (props.facultyContacts[contact].type === "University") {
          setFacultyEmailInput(`${props.facultyContacts[contact].emailAddress}`)
        }
      }
    }
  }, [props.facultyDemographics, props.facultyContacts]) // eslint-disable-line react-hooks/exhaustive-deps


//   handle snack bar error message on initial render of page.
//   checks to see that faculty, student, and course info is present in student and faculty fields.
    // useEffect(() => {
    //   if(Object.keys(props.facultyDemographics).length !== 0) {
    //     if(!props.facultyDemographics) {
    //       {
    //         setSubmitErrorMessage(
    //           <Typography>
    //             {<b>{`Something went wrong`}</b>}{<br/>}
    //             {`We couldn’t retrieve your details.  `}
    //             {`Please try again – if this issue continues, `} 
    //             {`please reach out to Tech Support at (800) 800-3493.`}
    //           </Typography>
    //         );
    //       }  
    //     }
    //   }
    // }, [props.facultyDemographics.status, props.facultyDemographics]) // eslint-disable-line react-hooks/exhaustive-deps
    // console.log(props.facultyDemographics)
    // useEffect(() => {
    //   if(Object.keys(props.facultyDemographics).length !== 0) {
    //     if(!props.facultyDemographics) {
    //         setSubmitErrorMessage(
    //           <Typography>
    //             {<b>{`Something went wrong`}</b>}{<br/>}
    //             {`We couldn’t retrieve your details.  `}
    //             {`Please try again – if this issue continues, `} 
    //             {`please reach out to Tech Support at (800) 800-3493.`}
    //           </Typography>
    //         )
    //     }
    //   }
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if( 
        props.facultyContacts.status !== undefined &&
        props.facultyContacts.status !== 200
      ) {
        setSubmitErrorMessage(
          <Typography>
            {<b>{`Something went wrong`}</b>}{<br/>}
            {`We couldn’t retrieve your university email address. `}
            {`Please try again – if this issue continues, `} 
            {`please reach out to Tech Support at (800) 800-3493.`}
          </Typography>
        )
      }
      if(props.facultyContacts.length !== undefined) {
        if (
            props.facultyContacts.length === 0
        ) {
          setSubmitErrorMessage(
            <Typography>
              {<b>{`Something went wrong`}</b>}{<br/>}
              {`We couldn’t retrieve your university email address.  `}
              {`Please try again – if this issue continues, `} 
              {`please reach out to Tech Support at (800) 800-3493.`}
            </Typography>
          )
        } else {
          let hasEmail = false;
          for(let contact = 0; contact < props.facultyContacts.length; contact++) {
            if (props.facultyContacts[contact].type === "University") {
              hasEmail = true;
            }
          }
          if(hasEmail === false) {
            setSubmitErrorMessage(
              <Typography>
                {<b>{`Something went wrong`}</b>}{<br/>}
                {`We couldn’t retrieve your university email address.  `}
                {`Please try again – if this issue continues, `} 
                {`please reach out to Tech Support at (800) 800-3493.`}
              </Typography>
            )
          }
        }
      }
    }, [props.facultyContacts.status, props.facultyContacts]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Box
        id="pageContainer"
        role="main"
        className={
          matches
            ? classes.pageContainer
            : tabletMatches
            ? classes.pageContainerTablet
            : classes.pageContainerMobile
        }
      >
        <Grid
          container
          md={12}
          sm={11}
          xs={10}
          className={
            matches
              ? classes.mainPageContent
              : tabletMatches
              ? classes.mainPageContentTablet
              : classes.mainPageContentMobile
          }
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            className={
              matches
                ? classes.linkContainer
                : tabletMatches
                ? classes.linkContainerTablet
                : classes.linkContainerMobile
            }
          >
            <Link
              id="backToDashboardLink"
              data-testid="back_to_dashboard_link"
              underline="none"
              className={classes.backToDashboardLink}
              href="/"
            >
              <img className={classes.arrowImage} src={BlackArrow} alt="" />
              <Typography>{`Course Dashboard`}</Typography>
            </Link>
          </Grid>

          <Grid
            item
            className={
              matches
                ? classes.pageHeaderContainer
                : classes.pageHeaderContainerMobile
            }
          >
            <Box>
              <Typography
                id="pageHeader"
                data-testid="page_header"
                component="h1"
                className={
                  matches
                    ? classes.pageHeader
                    : tabletMatches
                    ? classes.pageHeaderTablet
                    : classes.pageHeaderMobile
                }
              >
                {"Faculty Help Desk"}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            className={
              matches
                ? classes.pageIntroContainer
                : classes.pageIntroContainerMobile
            }
          >
            <Box sx={{ marginBottom: "48px" }}>
              <Typography id="pageIntro" data-testid="page_intro" className={classes.textContent}>
                {`If you’re having an issue with curriculum, or have a general or policy question not found in the Faculty Resource Center, please reach out for help using this form.`}
              </Typography>
              <Typography id="pageIntro" data-testid="page_intro" className={classes.textContent}>
                <span className={classes.numberedItem}>1 </span>{`Select the best category relating to your issue`}</Typography>
                <Typography id="pageIntro" data-testid="page_intro" className={classes.textContent}> 
                <span className={classes.numberedItem}>2 </span>{`Enter the specific details of your issue or your question`}</Typography>
                <Typography id="pageIntro" data-testid="page_intro" className={classes.textContent}> 
                {`Please call`} <span style={{ fontWeight: 'bold' }}> Faculty Technical Support </span> <span>at (800) 800-3493</span>
                {` for technical issues.`}
                </Typography>
            </Box>
          </Grid>

          <Grid
            item
            className={
              matches
                ? classes.mainInfoContainer
                : classes.mainInfoContainerMobile
            }
          >
            <form action={`${process.env.REACT_APP_HDSUBMIT_FORM_URL}`} method="POST">
              <input type="hidden" name="orgid" value={`${process.env.REACT_APP_HDSUBMIT_FORM_ORGID}`} />
              <input type="hidden" name="retURL" value={`${process.env.REACT_APP_HDSUBMIT_FORM_RETURL}`} />
              <input type="hidden" name="recordType" value={`${process.env.REACT_APP_HDSUBMIT_FORM_RECORD_TYPE}`} />
              <input type="hidden" name="type" value="Faculty Help Desk" />
              <input type="hidden" name="CC_Caller_Type__c" value="Faculty" />
              <input type="hidden" name="Reported_By__c" value="Active Faculty" />
              <input type="hidden" name="Receipt_Method__c" value="Faculty Portal" />
              <input type="hidden" name="Origin" value="Web" />
 
              <Box className={classes.hide}>
              <input type="hidden" name="Web_IRN__c" value={facultyIrnInput} />
              <input type="hidden" name="EAS_Faculty_Name__c" value={facultyNameInput} />
              <input type="hidden" name="EAS_Faculty_Email__c" value={facultyEmailInput} />
              <input type="hidden" name="Due_Date__c" value= {`${dueDate.getMonth() + 1}/${dueDate.getDate()}/${dueDate.getFullYear()}`} />
              <input type="hidden" name="Issue_Type_Tertiary_Fac__c" value={issueTypeSelected} />
              <input type="hidden" name="Faculty_Case_Subtype__c" value={caseTypeSelected} />
              <input type="hidden" name="description" value={comments} />
              <input type="hidden" name="subject" value={issueTypeSelected} />
              </Box>

            {tabletMatches ? (
              <Box
                sx={
                  matches
                    ? { width: "969px", display: "flex" }
                    : { width: "740px", display: "flex" }
                }
              >
                <Box
                  className={
                    matches
                      ? classes.textFieldsBox
                      : classes.textFieldsBoxTablet
                  }
                >
                <Typography id="requiredFields" data-testid="required_fields" className={classes.textContent}>
                {`*Required Information`}
              </Typography> 
                  {facultyFields.map((field) =>             
                   (<TextField 
                      id={field.id} 
                      data-testid={field.datatestID}
                      ariaLabel={field.ariaLabel}
                      label={field.label}
                      minRows="6"
                      maxRows="6"
                      value={field.inputVal}
                      disabled
                      variant="filled"
                      className={classes.inputTextFieldLocked} 
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />)
                    )}
                    
                    <div style={{marginBottom : "24px"}}> 
                    {props.caseTypes.length >= 0 ?
                      <TextField
                        select
                        id = "caseType"
                        data-testid = "case_type"
                        SelectProps = {{
                          SelectDisplayProps: {
                            "aria-describedby":"errorCaseType",
                            //"aria-invalid":{caseTypeErrorDisplay},
                            "aria-required": true,
                            "aria-expanded": caseTypeSelectExpand,
                            role: "combobox",
                          },
                        }}
                        required
                        role= "combobox"
                        aria-required="true"
                        aria-label = "case type"
                        aria-expanded={caseTypeSelectExpand}
                        value = {caseTypeSelected}
                        label = "Case Type" 
                        variant="filled"
                        className={classes.inputTextField}
                        onOpen={() => {
                          setCaseTypeSelectExpand(true);
                        }}
                        onClose={() => {
                          setCaseTypeSelectExpand(false);
                        }}
                        onChange={handleCaseTypeChange}
                      >
                        {props.caseTypes.map((item) => {
                          return (
                            <MenuItem
                              data-testid={item.label}
                              className={classes.menuItem}
                              key= {item.label}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      : (
                        <TextField
                        select
                        id = "caseType"
                        data-testid = "case_type"
                        SelectProps = {{
                          SelectDisplayProps: {
                            "aria-describedby":"errorCaseType",
                            "aria-required": true,
                            "aria-expanded": caseTypeSelectExpand,
                            role: "combobox",
                          }
                        }}
                        required
                        role="combobox"
                        aria-required="true"
                        aria-label = "case type"
                        aria-expanded={caseTypeSelectExpand}
                        value = {caseTypeSelected}
                        label = "Case Type" 
                        variant="filled"
                        className={classes.inputTextField}
                        onOpen={() => {
                          setCaseTypeSelectExpand(true);
                        }}
                        onClose={() => {
                          setCaseTypeSelectExpand(false);
                        }}
                        onChange={handleCaseTypeChange}
                      >
                      </TextField>
                      )}
                      
                      {caseTypeErrorDisplay &&
                    
                     <Box
                      className={classes.errorboxComments}
                      sx={
                        matches
                          ? { width: "609px" }
                          : tabletMatches
                          ? { width: "380px" }
                          : { width: "327px" }
                      }
                    >
                    <ErrorIcon
                      sx={{
                        color: "#B21F13",
                        height: "16px",
                        width: "16px",
                        padding: "9px 7px 9px 18px",
                      }}
                    />
                    <Typography
                      className={classes.errorboxText}
                      id="errorCaseType"
                      //role="alert"
                    >
                    {`Please select a case type`}
                    </Typography>
                  </Box>
                  }
                  </div>
                          
                   <div style={{marginBottom : "24px"}}>
                    <TextField
                      select
                      id = "issueType"
                      data-testid = "issue_type"
                      SelectProps = {{
                        SelectDisplayProps: {
                          "aria-expanded": issueTypeSelectExpand,
                          role: 'combobox',
                        },
                      }}
                      role = "combobox"
                      aria-label = "issue type"
                      aria-expanded={issueTypeSelectExpand}
                      onOpen={() => {
                        setIssueTypeSelectExpand(true);
                      }}
                      onClose={() => {
                        setIssueTypeSelectExpand(false);
                      }}
                      value = {issueTypeSelected}
                      label = "Issue Type"
                      variant="filled"
                      className={classes.inputTextField} 
                      onChange={handleIssueTypeChange}
                    >
                      {props.issueTypes.length >= 0 &&
                        props.issueTypes.map((item) => {
                          return (
                            <MenuItem
                              data-testid={item.label}
                              className={classes.menuItem}
                              key={item.label}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })
                      }
                    </TextField> 
                    </div>
                      
                     <div>
                    {commentsErrorDisplay ? (
                    <Box
                      sx={
                        matches
                          ? { width: "609px", marginBottom: "64px" }
                          : tabletMatches
                          ? { width: "380px", marginBottom: "64px" }
                          : { width: "327px", marginBottom: "64px" }
                      }
                    > 
                    <TextField 
                            
                    id = "label"
                    required
                    data-testid = "label"
                    aria-label = "label"
                    InputProps = {{
                      inputProps: {
                         "aria-describedby":"errorComments",
                         "aria-required":"true",
                         tabIndex:"0"
                      },
                    }}
                    value = {comments}
                    label ="Comments"
                    multiline
                    minRows="6"
                    maxRows="6"
                    variant="filled"
                    className= {classes.labelInputTextField}
                    onChange={(event) => setComments(event.target.value)}
                              
                  />
                  <Box
                    className={classes.errorboxComments}
                    sx={
                      matches
                        ? { width: "609px" }
                        : tabletMatches
                        ? { width: "380px" }
                        : { width: "327px" }
                    }
                  >
                  <ErrorIcon
                    sx={{
                      color: "#B21F13",
                      height: "16px",
                      width: "16px",
                      padding: "9px 7px 9px 18px",
                    }}
                  />
                  <Typography
                    className={classes.errorboxText}
                    //role="alert"
                    //aria-live="assertive"
                    id="errorComments"
                  >{`Please add your details`}</Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={
                  matches
                    ? { width: "609px", marginBottom: "64px" }
                    : tabletMatches
                    ? { width: "380px", marginBottom: "64px" }
                    : { width: "327px", marginBottom: "64px" }
                }
              >
               <TextField 
                      
                      id = "label"
                      required
                      data-testid = "label"
                      aria-label = "label"
                      value = {comments}
                      label ="Comments"
                      multiline
                      minRows="6"
                      maxRows="6"
                      variant="filled"
                      className= {classes.labelInputTextField}
                      onChange={(event) => setComments(event.target.value)}
                                
                    /> 
              </Box>
            )}
            </div> 
                </Box>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box className={classes.textFieldsBoxMobile}>
                <Typography id="requiredFields" data-testid="required_fields" className={classes.textContent}>
                {`*Required Information`}
                </Typography>
                  {facultyFields.map((field) => 
                      (<TextField
                        id={field.id}
                        data-testid={field.datatestID}
                        aria-label={field.ariaLabel}
                        label={field.label}
                        value={field.inputVal}
                        disabled
                        variant="filled"
                        className= {classes.inputTextFieldLocked} 
                        InputProps={ {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />)
                      )}

                      <div style={{marginBottom : "24px"}}> 
                      {props.caseTypes.length >= 0 ? 
                      <TextField
                      select
                      id = "caseType"
                      SelectProps = {{
                        SelectDisplayProps: {
                          "aria-describedby":"errorCaseType",
                          "aria-required": true,
                          "aria-expanded": caseTypeSelectExpand,
                          role: 'combobox',
                        }
                      }}
                      aria-required = "true"
                      role="combobox"
                      required
                      data-testid = "case_type"
                      aria-label = "case type"
                      aria-expanded={caseTypeSelectExpand}
                      onOpen={() => {
                        setCaseTypeSelectExpand(true);
                      }}
                      onClose={() => {
                        setCaseTypeSelectExpand(false);
                      }}
                      value = {caseTypeSelected}
                      label = "Case Type"
                      backgroundColor = "#ffffff"
                      variant="filled"
                      className={classes.inputTextField} 
                      onChange={handleCaseTypeChange}
                      >
                        {props.caseTypes.map((item) => {
                        return (
                        <MenuItem
                            data-testid="menuItem"
                            className={classes.menuItem}
                            key={item.id}
                            value={item.label}
                        >
                            {item.label}
                        </MenuItem>
                        );
                    })}
                      </TextField> 
                      : (
                        <TextField
                      select
                      id = "caseType"
                      SelectProps = {{
                        SelectDisplayProps: {
                          "aria-describedby":"errorCaseType",
                          "aria-required": true,
                          "aria-expanded": caseTypeSelectExpand,
                          role: 'combobox',
                        }
                      }}
                      aria-required = "true"
                      role="combobox"
                      required
                      data-testid = "case_type"
                      aria-label = "case type"
                      aria-expanded={caseTypeSelectExpand}
                      onOpen={() => {
                        setCaseTypeSelectExpand(true);
                      }}
                      onClose={() => {
                        setCaseTypeSelectExpand(false);
                      }}
                      value = {caseTypeSelected}
                      label = "Case Type"
                      backgroundColor = "#ffffff"
                      variant="filled"
                      className={classes.inputTextField} 
                      onChange={handleCaseTypeChange}
                      >
                      </TextField> 
                      )}
                       
                       {caseTypeErrorDisplay &&
                    
                        <Box
                         className={classes.errorboxComments}
                         sx={
                           matches
                             ? { width: "513px" }
                             : tabletMatches
                             ? { width: "380px" }
                             : { width: "327px" }
                         }
                       >
                       <ErrorIcon
                         sx={{
                           color: "#B21F13",
                           height: "16px",
                           width: "16px",
                           padding: "9px 7px 9px 18px",
                         }}
                       />
                       <Typography
                         className={classes.errorboxText}
                         id="errorCaseType"
                       >
                       {`Please select a case type`}
                       </Typography>
                     </Box>
                     }
                      </div>

                      <div style={{marginBottom : "24px"}}> 
                      <TextField
                      select
                      id = "issueType"
                      SelectProps = {{
                        SelectDisplayProps: {
                          "aria-expanded": issueTypeSelectExpand,
                          role: 'combobox',
                        },
                      }}
                      data-testid = "issue_type"
                      role="combobox"
                      aria-label = "issue type"
                      aria-expanded={issueTypeSelectExpand}
                      onOpen={() => {
                        setIssueTypeSelectExpand(true);
                      }}
                      onClose={() => {
                        setIssueTypeSelectExpand(false);
                      }}
                      value = {issueTypeSelected}
                      label = "Issue Type"
                      backgroundColor = "#ffffff"
                      //disabled={true}
                      variant="filled"
                      className={classes.inputTextField} 
                      onChange={handleIssueTypeChange}
                      >
                         {props.issueTypes.length >= 0 &&
                        props.issueTypes.map((item) => {
                        return (
                        <MenuItem
                            data-testid="menuItem"
                            className={classes.menuItem}
                            key={item.id}
                            value={item.label}
                        >
                            {item.label}
                        </MenuItem>
                        );
                    })}
                      </TextField>
                      </div>
                      <div>
                      {commentsErrorDisplay ? (
                    <Box
                      sx={
                        matches
                          ? { width: "609px", marginBottom: "64px" }
                          : tabletMatches
                          ? { width: "380px", marginBottom: "64px" }
                          : { width: "327px", marginBottom: "64px" }
                      }
                    > 
                    <TextField      
                    id = "label"
                    required
                    aria-required
                    data-testid = "label"
                    aria-label = "label"
                    InputProps = {{
                      inputProps: {
                         "aria-describedby":"errorComments",
                         "aria-required":"true",
                         tabIndex:"0"
                      },
                    }}
                    value = {comments}
                    label ="Comments"
                    multiline
                    minRows="6"
                    maxRows="6"
                    variant="filled"
                    className= {classes.labelInputMobileTextField}
                    onChange={(event) => setComments(event.target.value)}
                              
                  />
                  <Box
                    className={classes.errorboxComments}
                    sx={
                      matches
                        ? { width: "513px" }
                        : tabletMatches
                        ? { width: "380px" }
                        : { width: "327px" }
                    }
                  >
                  <ErrorIcon
                    sx={{
                      color: "#B21F13",
                      height: "16px",
                      width: "16px",
                      padding: "9px 7px 9px 18px",
                    }}
                  />
                  <Typography
                    className={classes.errorboxText}
                    id="errorComments"
                  >{`Please add your details`}</Typography>
                </Box>
              </Box>  
                    ):(
                      <TextField
                      id = "label"
                      required
                      aria-required
                      data-testid = "label"
                      aria-label = "label"
                      value = {comments}
                      label ="Comments"
                      multiline
                      minRows="6"
                      maxRows="6"
                      variant="filled"
                      className= {classes.labelInputMobileTextField}
                      onChange={(event) => setComments(event.target.value)}
                      >
                      </TextField>
                    )} 
                    </div> 
                </Box>
              </Box>
            )}            
              
              {/* <Box
              sx={
                matches
                  ? { width: "609px", marginBottom: "64px" }
                  : tabletMatches
                  ? { width: "380px", marginBottom: "64px" }
                  : { width: "327px", marginBottom: "64px" }
              }
            >
             </Box> */}
             
              <Box sx={{ marginBottom: "152px" }}>
                  <Button
                      type="submit"
                      name="submit"
                      id="submit_button"
                      data-testid="submit_button"
                      aria-label="Sumit to help desk"
                      variant="outlined"
                      className={classes.submitButton}
                      onClick={(event) => {handleSubmit(event)}}
                  >
                      {"Submit"}
                  </Button>
              </Box>
             
              </form>
          </Grid>
        </Grid>
      </Box>
      {openSuccess &&
        (submitErrorMessage !== ""
          ? renderSnackbar("error", submitErrorMessage)
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    facultyDemographics: state.demographics,
    facultyContacts: state.facultyContacts,
    caseTypes:state.caseTypes,
    issueTypes: state.issueTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {      
      getCaseTypes: () => dispatch(getCaseTypes()),
      getIssueTypes: (caseTypes) => dispatch(getIssueTypes(caseTypes))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskSubmissionPage);