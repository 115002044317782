import { getAccommodations } from "../../services/faculty/v1/faculty";
import { GET_STUDENT_ACCOMMODATIONS} from "./actionTypes";

export const getStudentAccommodations = (filter) => async (dispatch) => {
  await  getAccommodations(filter)
    .then((response) => {
      dispatch({ type: GET_STUDENT_ACCOMMODATIONS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_STUDENT_ACCOMMODATIONS, payload: error.request });
    });
};