import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Box, Link, Tabs, Tab, styled, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import facultyPortalStyles from "../../../styles";
import useStyles from "../styles";
import BlackArrow from "../../../images/BlackArrow.svg";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import MyWorkshops from "./MyWorkshops";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpcomingWorkshops from "./UpcomingWorkshops";
import WorkshopFAQs from "./WorkshopFAQs";
import {
  getWorkshopList,
  getSectionsByIrn,
} from "../../../redux/workshops/workshopActions";
import { storeErrorMessages } from "../../../redux/displayApiErrors/displayApiErrorsActions";
import { connect } from "react-redux";

const FacultyWorkshops = (props) => {
  document.title = "Faculty Portal Faculty Workshops";
  const classes = useStyles();
  const facultyClasses = facultyPortalStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));

  const [openSuccess, setOpenSuccess] = useState(false);
  const [wsOpenSuccess, setWsOpenSuccess] = useState(false);
  const [wsSectionsOpenSuccess, setWsSectionsOpenSuccess] = useState(false);
  const [value, setValue] = useState(1);
  const [title, setTitle] = useState("My workshops");
  const [expand, setExpand] = useState(false);
  const [wsUser, setWsUser] = useState(false);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  // Below code is used to get workshops and workshops sections by irn
  // -------------------------------------------------------------------------------------------------------------
  const setOneYearAhead = () => {
    let endDate = new Date();
    const newYear = endDate.getYear() + 1;
    endDate.setFullYear(1900 + newYear);
    return endDate;
  };

  const workshopObjectToRetrieveOneYear = {
    fq: "upcomingWorkshops",
    startDate: new Date(
      new Date().setTime(new Date().getTime() + 1 * 86400000)
    ).toLocaleDateString("en-US", { timeZone: "America/Phoenix" }),
    //startDate: new Date().toISOString(),
    endDate: setOneYearAhead().toLocaleDateString("en-US", {
      timeZone: "America/Phoenix",
    }),
    courseCode: "",
    courseTitle: "",
    irn: "",
    username: "",
  };

  useEffect(() => {
    handleGetWorkshops(workshopObjectToRetrieveOneYear);
    handleGetSectionsForIrn(props.demographics.issuerId);
  }, [props.demographics.issuerId, value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.demographics.username) {
      process.env.REACT_APP_WS_USERS_USERNAME_LIST.includes(
        props.demographics.username
      )
        ? setWsUser(true)
        : setWsUser(false);
    }
  }, [props.demographics.username]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetWorkshops = (workshopObject) => {
    props.getWorkshopList(workshopObject);
  };

  const handleGetSectionsForIrn = (irn) => {
    if (irn) {
      props.getSectionsByIrn(irn);
    }
  };
  // -------------------------------------------------------------------------------------------------------------

  // Below code is used to handle tab panel on page
  // -------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (value === 0) {
      setTitle("My workshops");
    } else if (value === 1) {
      setTitle("Upcoming Workshops");
    } else if (value === 2) {
      setTitle("Workshop FAQs");
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    console.log(event, newValue);
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Grid
        item
        sm={8}
        md={value === 2 ? 5 : 7}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={
              matches
                ? { p: 3, padding: 0, marginLeft: "75px" }
                : { p: 3, padding: 0 }
            }
          >
            <Typography sx={{ textTransform: "none" }}>{children}</Typography>
          </Box>
        )}
      </Grid>
    );
  }

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      minWidth: "185px",
      color: "#001823",
      "&.Mui-selected": {
        backgroundColor: "#ffffff",
        color: "#DB3725",
      },
      "&.Mui-focusVisible": {
        border: "2px solid #001823"
      }
    })
  );

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    setExpand(false);
  }, [title]);

  // -------------------------------------------------------------------------------------------------------------

  // Below code deals with Snackbar
  // -------------------------------------------------------------------------------------------------------------
  function renderSnackbar(snackbarType, message) {
    return props.errorMessages.length > 1 ? (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={openSuccess}
          autoHideDuration={null}
          onClose={handleClose}
        >
          <SnackbarContentWrapper
            onClose={handleClose}
            handleRetryClick={handleRetryApiCall}
            variant={snackbarType}
            message={message}
            noRetryButton
          />
        </Snackbar>
      </>
    ) : (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={openSuccess}
          autoHideDuration={null}
          onClose={handleClose}
        >
          <SnackbarContentWrapper
            onClose={handleClose}
            handleRetryClick={handleRetryApiCall}
            variant={snackbarType}
            message={message}
          />
        </Snackbar>
      </>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleRetryApiCall = () => {
    if (props.workshops.status !== 200) {
      handleGetWorkshops(workshopObjectToRetrieveOneYear);
    }
    if (props.demographics.issuerId) {
      if (props.sectionsForIrn.status !== 200) {
        handleGetSectionsForIrn(props.demographics.issuerId);
      }
    }
  };

  useEffect(() => {
    if (
      props.workshops.status !== undefined &&
      props.workshops.status !== 200
    ) {
      props.storeErrorMessages("Workshops information not found.", "workshops");
    } else {
      setWsOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [props.workshops.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.sectionsForIrn.status !== undefined &&
      props.sectionsForIrn.status !== 200
    ) {
      props.storeErrorMessages(
        "Workshop sections information not found.",
        "sectionsForIrn"
      );
    } else {
      setWsSectionsOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [props.sectionsForIrn.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.errorMessages.length > 1) {
      setOpenSuccess(true);
    }
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "workshops"
    ) {
      setWsOpenSuccess(true);
      setOpenSuccess(true);
    }
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "sectionsForIrn"
    ) {
      setWsSectionsOpenSuccess(true);
      setOpenSuccess(true);
    }
  }, [props.errorMessages]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------------------------------------------------------------------

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <div
        className={
          matches
            ? facultyClasses.pageContainer
            : facultyClasses.pageContainerMobile
        }
        id="main"
        role="main"
      >
        <Grid className={matches ? classes.linkBox : classes.linkBoxMobile}>
          <Link
            underline="none"
            sx={{
              color: "#001823",
              display: "flex",
              justifyContent: "space-between",
              "&hover": {
                cursor: "pointer",
              },
            }}
            href="/"
          >
            <img
              className={classes.arrowImage}
              src={BlackArrow}
              alt="arrowImage"
            />
            <Typography>{`Course Dashboard`}</Typography>
          </Link>
        </Grid>
        <Grid
          container
          // md={9}
          // sm={12}
          // xs={11}
          direction="column"
          className={matches ? classes.mainContent : classes.mainContentMobile}
        >
          <Typography
            variant="h1"
            component="h1"
            className={
              matches || tabletMatches ? classes.title : classes.mobileTitle
            }
          >
            {`Faculty Workshops`}
          </Typography>
        </Grid>

        {!matches && !tabletMatches ? (
          <>
            <div className={classes.dropdownContainer}>
              {expand ? (
                <Box
                  className={facultyClasses.notificationContainerMobile}
                  data-testid="WorkshopContainer"
                  aria-expanded={expand}
                  aria-label={props.notificationAriaLabel}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      gap: "7px",
                      marginRight: "5px",
                    }}
                  >
                    <Button
                      onClick={() => setValue(0)}
                      sx={{
                        color: `${
                          value !== 0 ? "#000000 !important" : "#dc3727"
                        }`,
                        textTransform: "none",
                        fontSize: "1rem",
                        width: "100%",
                        marginTop: "12px",
                      }}
                    >
                      My workshops
                    </Button>
                    <Button
                      onClick={() => setValue(1)}
                      sx={{
                        color: `${
                          value !== 1 ? "#000000 !important" : "#dc3727"
                        }`,
                        textTransform: "none",
                        fontSize: "1rem",
                        width: "300px",
                      }}
                    >
                      Upcoming workshops
                    </Button>
                    <Button
                      onClick={() => setValue(2)}
                      sx={{
                        color: `${
                          value !== 2 ? "#000000 !important" : "#dc3727"
                        }`,
                        textTransform: "none",
                        fontSize: "1rem",
                        width: "300px",
                        marginBottom: "10px",
                      }}
                    >
                      Workshop FAQs
                    </Button>
                  </Box>
                  <ExpandMoreIcon
                    onClick={() => {
                      setExpand(!expand);
                    }}
                    classes={{ root: classes.expandIconRootExpanded }}
                    sx={{ marginLeft: "-28px", marginRight: "5px" }}
                  />
                </Box>
              ) : (
                <Box
                  className={facultyClasses.notificationContainerMobile}
                  data-testid="NotificationContainer"
                  aria-expanded={expand}
                  aria-label={props.notificationAriaLabel}
                  onClick={() => {
                    setExpand(!expand);
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <Box className={classes.infoContainerMobile}>
                    <Typography
                      variant="body1"
                      sx={{ color: "#000304" }}
                      component="p"
                    >
                      {title.length >= 30
                        ? `${title.substring(0, 30)}...`
                        : title}
                    </Typography>
                  </Box>
                  <ExpandMoreIcon classes={{ root: classes.expandIconRoot }} />
                </Box>
              )}
              <Box
                sx={{
                  width: "357px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TabPanel value={value} index={0}>
                  <MyWorkshops setValue={setValue} setTitle={setTitle} wsUser={wsUser} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <UpcomingWorkshops setValue={setValue} wsUser={wsUser} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <WorkshopFAQs setValue={setValue} />
                </TabPanel>
              </Box>
            </div>
          </>
        ) : (
          <Grid
            container
            md={12}
            className={
              !matches && tabletMatches
                ? classes.tabletTabContainer
                : classes.tabContainer
            }
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{
                overflow: "inherit",
                borderRight: 1,
                borderColor: "divider",
              }}
            >
              <StyledTab label="My workshops" {...a11yProps(0)} />
              <StyledTab label="Upcoming workshops" {...a11yProps(1)} />
              <StyledTab label="Workshop FAQs" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <MyWorkshops setValue={setValue} wsUser={wsUser} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UpcomingWorkshops setValue={setValue} wsUser={wsUser} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <WorkshopFAQs setValue={setValue} />
            </TabPanel>
          </Grid>
        )}
      </div>

      {openSuccess &&
        (props.errorMessages.length > 1
          ? renderSnackbar("error", "Oops! Something went wrong.")
          : "")}
      {wsOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
      {wsSectionsOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    errorMessages: state.errorMessages,
    workshops: state.workshops,
    sectionsForIrn: state.sectionsForIrn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkshopList: (workshopObject) =>
      dispatch(getWorkshopList(workshopObject)),
    getSectionsByIrn: (irn) => dispatch(getSectionsByIrn(irn)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FacultyWorkshops);
