import { makeStyles } from "@material-ui/core";

import Avatar from "./Avatar";
import ProfileInfo from "./ProfileInfo";

const userStyles = makeStyles({
  // Component only used in Desktop View
  profileContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "4%",
  },
});

const ProfileBox = () => {
  const classes = userStyles();

  return (
    <div className={classes.profileContainer}>
      <Avatar />
      <ProfileInfo />
    </div>
  );
};

export default ProfileBox;
