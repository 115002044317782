import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  container: {
    paddingBottom: "100px",
    marginTop: "40px",
  },
  title: {
    paddingBottom: "15px",
  },
  unorderedList: {
    // width: "100%",
    // listStyleType: "none",
    // margin: 0,
    // padding: 0,
    // display: "flex",
    // justifyContent: "space-between",
  },

  // Mobile View
  mobileContainer: {
    minWidth: "327px",
    height: "191px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    marginBottom: "88px",
    marginTop: "26px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  mobilePaddingContainer: {
    display: "flex",
    paddingLeft: "21px",
    paddingBottom: "20px",
    flexDirection: "column",
  },
  cardTitle: {
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  linkContainer: {
    paddingBottom: "20px",
  },
  cardLink: {
    paddingTop: "20px",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "18.75px",
    borderBottom: "solid 3px",
    color: "#000000",
    "&:hover": {
      textDecoration: "none",
    },
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },
}));

export default useStyles;
