import { proxyClientInstance } from "../../wrappers/client";

export const getFacultyDemographics = (personId) => {
  const getUrl = `/api/persons/v1/person/${personId}/personName`;
  return proxyClientInstance.get(getUrl);
};

export const getPersonProfile = (profileId) => {
  const url = `/api/faculty/v1/person/names/${profileId}?type=student`;
  return proxyClientInstance.get(url);
};

export const getPersonWithName = (irn, personType) => {
  const url = `/api/faculty/v1/person/name?irn=${irn}&personType=${personType}`;
  return proxyClientInstance.get(url);
};