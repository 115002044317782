import { GET_PERSON_WITH_NAME, GET_PERSON_WITH_NAME_SUCCESS } from "./actionTypes";
import { selectPersonByIrnAndType } from "./personsWithNameSelector";
const initialState = {
  personsWithNameMap: {},
  error: null
};

// eslint-disable-next-line 
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PERSON_WITH_NAME:
      return {
        ...state,
        error: null
      };
    case GET_PERSON_WITH_NAME_SUCCESS:
      return {
        ...state,
        personsWithNameMap: addUniquePersonToMap(state.personsWithNameMap, action.payload),
        error: null
      };

    default:
      return state;
  }
}

function addUniquePersonToMap(personMap, newPerson) {
  if (!selectPersonByIrnAndType(
    personMap,
    newPerson.issuerId,
    newPerson.type)) {
    // If the person doesn't exist, add them to the array
    return {
      ...personMap,
      [`${newPerson.issuerId}-${newPerson.type}`]: newPerson
    };
  };
  return personMap;
}

